import { ComponentType } from "./definition"
import { LazyMap } from "./utils"

/** @internal */
export const localPackageFallbackIdentifier = "|local|" // Contains characters that do not exist in normal names

/**
 * Every Framer-generated package will have an index module
 * that is expected to export some special values.
 * @internal
 */
export type FramerIndexModule = {
    exports: FramerModuleExports
}

/**
 * The expected exported object from the index modules generated by Framer.
 * @internal
 * TODO: delete this type when all the framer runtime logic is extracted to Source/Runtime
 */
export type FramerModuleExports = {
    __framer__?: FramerMetadata
}

// TODO: Share this type with Server.
// TODO: delete this type when all the framer runtime logic is extracted to Source/Runtime
/** @internal */
type ComponentInfo = {
    name: string
    children: boolean
    type: ComponentType
}

/**
 * @internal
 * TODO: delete this type when all the framer runtime logic is extracted to Source/Runtime
 * */
type FramerMetadata = {
    packageJson: {
        name?: string // Can be undefined for the local package.
        framer?: {
            components?: ComponentInfo[]
            displayName?: string
        }
        design?: any
    }
    dependencies?: LazyMap<FramerModuleExports>
    sourceModules?: LazyMap<SourceModuleExports>
}

/**
 * @internal
 * TODO: delete this type when all the framer runtime logic is extracted to Source/Runtime
 * */
type SourceModuleExports = {
    __info__?: ComponentInfo[]
    error?: any
}
