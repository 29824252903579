import * as React from "react"
import { LayoutTree } from "./SharedLayoutTree"
import { createAnimation, Config } from "./animation"
import { LayoutTreeContext, LayoutTreeContextProps } from "./TreeContext"
import { animatableStyles } from "../../animation/Motion/animateStyle"
import {
    createBatcher,
    startAnimation,
    MotionValue,
    AxisBox2D,
    SharedLayoutContext,
    SharedLayoutSyncMethods,
    SyncLayoutLifecycles,
    HTMLVisualElement,
} from "framer-motion"
import { mix, linear, progress } from "popmotion"

const syncContextStub = {
    register: () => {},
    remove: () => {},
    add: () => {},
    flush: () => {},
    syncUpdate: () => {},
}

/**
 * @internal
 */
export class AnimateLayoutTrees extends React.Component<{}, { foo: number }> {
    private lead: LayoutTree | undefined
    private follow: LayoutTree | undefined
    private safeToRemoveTree: LayoutTree | undefined

    private scheduled = false
    private resetScheduled = false
    private layoutIdConfig = new Map<string, Config>()

    private stopCrossfade?: () => void

    treeContext: LayoutTreeContextProps = {
        promoteTree: (...args) => this.promoteTree(...args),
        markTreeAsSafeToRemove: (tree: LayoutTree) => this.markTreeAsSafeToRemove(tree),
    }

    /**
     * Provide a dummy syncContext with a forceUpdate method to enable AnimatePresence
     * to remove trees when they are animating their removal.
     */
    syncContext: SharedLayoutSyncMethods = {
        ...syncContextStub,
        forceUpdate: () => {
            this.syncContext = {
                ...this.syncContext,
            }
            this.forceUpdate()
        },
    }

    private batch = createBatcher()

    /**
     * When a new LayoutTree is mounted, or becomes the lead tree,
     * it flags itself as the new lead tree in it's shouldComponentUpdate lifecycle method.
     * We then preform the batched writes and reads on the children of the new lead tree,
     * and any children of the previous lead tree that share a layoutId with children in the lead tree.
     * Finally, we flag that an animation is scheduled.
     *
     * The return value lets the LayoutTree know whether or not it should perform an update.
     * It shouldn't if for some reason the tree is already the lead tree, or shouldn't perform
     * a magic motion transition.
     */
    promoteTree(tree: LayoutTree, shouldAnimate: boolean, transition: any, resets?: boolean): boolean {
        if (tree === this.lead) return false

        this.follow = this.lead
        this.lead = tree

        if (resets) this.resetScheduled = true

        if (!shouldAnimate) {
            resetCrossfade(tree)
            return false
        }

        this.layoutIdConfig.clear()

        const currentStyle = {}

        /**
         * Write: Since we're supporting bounding box-distorting transforms, reset them before
         * measuring the bounding box.
         */
        for (const [layoutId, lead] of this.lead.children) {
            const follow = this.follow?.children.get(layoutId)

            snapshotRotate(layoutId, lead, follow, currentStyle)

            follow && resetRotate(follow)
            resetRotate(lead)
        }

        /**
         * Read: Snapshot children, register the follow's props to the ShouldAnimateContext,
         * and finally create an animation config.
         */
        for (const [layoutId, lead] of this.lead.children) {
            const follow = this.follow?.children.get(layoutId)

            follow?.snapshotBoundingBox()
            lead.snapshotBoundingBox()

            snapshotAutoValues(layoutId, follow, currentStyle)

            const prevViewportBox = follow?.prevViewportBox ? copyAxisBox(follow?.prevViewportBox) : undefined

            // Create the config.
            // In future this is where we would use the lead's transition if it were set in a timeline.
            const config: Config = {
                lead,
                current: currentStyle[layoutId],
                transition,
                prevViewportBox,
                shouldStackAnimate: follow ? true : false,
            }

            this.layoutIdConfig.set(layoutId, config)
        }

        this.scheduled = true
        return true
    }

    /**
     * Set a LayoutTree as being removed by framer-motion.
     * When AnimateLayoutTrees updates, we will call safeToRemove() on all children of this tree
     * so that AnimatePresence can remove the entire tree from the DOM when an exit animation completes.
     */
    markTreeAsSafeToRemove(tree: LayoutTree) {
        this.safeToRemoveTree = tree
    }

    /**
     * When AnimateLayoutTrees updates, if a tree has been marked as being safe to remove,
     * we iterate through that tree's children and call safeToRemove on them, ensuring that
     * AnimatePresence knows it shouldn't wait for those components to complete an exit animation.
     * We must call these functions in componentDidUpdate because safeToRemove won't be set on
     * children that are not yet being removed.
     */
    markTreeChildrenAsSafeToRemove(tree?: LayoutTree) {
        if (!tree) return
        for (const [_, child] of tree.children) child.config?.safeToRemove?.()
    }

    /**
     * When a tree has promoted itself to be the new lead in it's shouldComponentUpdate lifecycle method,
     * and has scheduled animations, we perform those when the component has updated.
     */
    componentDidUpdate() {
        if (this.scheduled) this.startLayoutAnimation(this.resetScheduled)
        if (this.safeToRemoveTree) this.markTreeChildrenAsSafeToRemove(this.safeToRemoveTree)

        this.safeToRemoveTree = undefined
        this.scheduled = false
        this.resetScheduled = false
    }

    startLayoutAnimation(shouldReset: boolean) {
        const { lead, follow } = this
        const leadChildren = lead?.children
        const followChildren = follow?.children

        const handler: SyncLayoutLifecycles = {
            measureLayout: child => child.measureLayout(),
            layoutReady: child => {
                const { layoutId } = child
                if (layoutId === undefined) return

                const config = this.layoutIdConfig.get(layoutId)
                if (!config) return child.layoutReady({ shouldStackAnimate: false })

                const isLead = Boolean(leadChildren && leadChildren.get(layoutId) === child)
                const animate = createAnimation(config, isLead)

                child.layoutReady(animate)
            },
        }

        /**
         * Shared layout animations can be used without the AnimateSharedLayout wrapping component.
         * This requires some co-ordination across components to stop layout thrashing
         * and ensure measurements are taken at the correct time.
         *
         * Here we use that same mechanism of schedule/flush.
         */
        if (lead && leadChildren) {
            for (const [_, child] of leadChildren) this.batch.add(child)
            lead.layoutMayBeMutated = false
        }

        if (!shouldReset && follow && followChildren) {
            for (const [_, child] of followChildren) this.batch.add(child)
            follow.layoutMayBeMutated = true
        }

        this.batch.flush(handler)

        this.stopCrossfade = this.startCrossfade()
    }

    startCrossfade() {
        this.stopCrossfade?.()

        if (!this.lead?.rootChild) return

        const isExit = this.follow?.isExiting
        const value = new MotionValue(0)

        const leadRoot = this.lead?.rootChild?.getValue("opacity", 0)
        const followRoot = this.follow?.rootChild?.getValue("opacity", 1)

        if (isExit) leadRoot.set((this.lead.rootChild.config as any).style.opacity || 1)

        const frame = () => {
            const p = value.get()

            if (isExit) return followRoot?.set(easeCrossfadeOut(mix(1, 0, p)))

            return leadRoot?.set(easeCrossfadeIn(mix(0, 1, p)))
        }

        startAnimation(
            "crossfade",
            value,
            1,
            this.lead.props.transition || this.lead.rootChild.config.transition || defaultTransition
        )

        const cancel = value.onChange(frame)

        return () => {
            value.stop()
            cancel()
        }
    }

    render() {
        return (
            <LayoutTreeContext.Provider value={this.treeContext}>
                <SharedLayoutContext.Provider value={this.syncContext}>
                    {this.props.children}
                </SharedLayoutContext.Provider>
            </LayoutTreeContext.Provider>
        )
    }
}

const easeCrossfadeIn = compress(0, 0.5, linear)
const easeCrossfadeOut = compress(0, 0.3, linear)

function compress(min: number, max: number, easing: any): any {
    return (p: number) => {
        // Could replace ifs with clamp
        if (p < min) return 0
        if (p > max) return 1
        return easing(progress(min, max, p))
    }
}

function copyAxisBox(box?: AxisBox2D) {
    if (!box) return undefined

    return {
        x: { ...box.x },
        y: { ...box.y },
    }
}

function resetCrossfade(tree: LayoutTree) {
    tree.rootChild?.getValue("opacity", 1).set((tree.rootChild.config as any)?.style?.opacity || 1)
}

function snapshotRotate(
    layoutId: string,
    lead: HTMLVisualElement,
    follow: HTMLVisualElement | undefined,
    styleMap: Record<string, Record<string, any>>
) {
    const followRotate = follow?.getValue("rotate")
    const leadRotate = lead.getValue("rotate")
    styleMap[layoutId] = { rotate: leadRotate?.isAnimating() ? leadRotate.get() : followRotate?.get() || 0 }
}

function snapshotAutoValues(
    layoutId: string,
    follow: HTMLVisualElement | undefined,
    styleMap: Record<string, Record<string, any>>
) {
    for (const value of animatableStyles) {
        if (value === "rotate") continue
        styleMap[layoutId][value] = follow?.getValue(value)?.get()
    }
}

const defaultTransition = {
    duration: 0.45,
    ease: [0.4, 0, 0.1, 1],
}

const transformAxes = ["", "X", "Y", "Z"]

function resetRotate(child: HTMLVisualElement) {
    // If there's no detected rotation values, we can early return without a forced render.
    let hasRotate = false

    // Keep a record of all the values we've reset
    const resetValues = {}

    // Check the rotate value of all axes and reset to 0
    transformAxes.forEach(axis => {
        const key = "rotate" + axis

        // If this rotation doesn't exist as a motion value, then we don't
        // need to reset it
        if (!child.hasValue(key)) return

        hasRotate = true

        // Record the rotation and then temporarily set it to 0
        resetValues[key] = child.latest[key]
        child.latest[key] = 0
    })

    // If there's no rotation values, we don't need to do any more.
    if (!hasRotate) return

    // Force a render of this element to apply the transform with all rotations
    // set to 0.
    child.render()

    // Put back all the values we reset
    for (const key in resetValues) {
        child.latest[key] = resetValues[key]
    }

    // Schedule a render for the next frame. This ensures we won't visually
    // see the element with the reset rotate value applied.
    child.scheduleRender()
}

type MappedHTMLElement = [string, HTMLVisualElement]

function sortMapByDepth([_, a]: MappedHTMLElement, [__, b]: MappedHTMLElement) {
    return a.depth - b.depth
}
