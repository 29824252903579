import { Typeface, TypefaceSourceNames, ReadonlyTypeface, TypefaceLocator, ReadonlyFont, Font, FontMetadata, CustomFontAssetData } from "./types"

export const customFontSelectorPrefix = "CUSTOM;"
/** @internal */
export class CustomFontSource {
    name: TypefaceSourceNames = TypefaceSourceNames.Custom
    typefaces: Typeface[] = []
    byFamily = new Map<string, Typeface>()
    byAssetKey = new Map<string, string>()

    importFonts(fontAssetMap: Map<string, CustomFontAssetData>): Font[] {
        this.typefaces.length = 0
        this.byFamily.clear()
        this.byAssetKey.clear()

        const fonts: Font[] = []

        fontAssetMap.forEach((family, assetKey) => {
            const typeface: Typeface = this.createTypeface(family.name)
            const font: Font = {
                typeface,
                selector: `${customFontSelectorPrefix}${family.name}`,
                variant: this.inferVariantName(family.name),
                postscriptName: family.properties.postscriptName,
            }

            typeface.fonts.push(font)
            typeface.owner = family.owner

            this.byAssetKey.set(family.name, assetKey)
            fonts.push(...typeface.fonts)
        })

        return fonts
    }

    inferVariantName(family: string) {
        const possibleValues = ["thin", "ultra light", "extra light", "light", "normal", "medium", "semi bold", "bold", "extra bold", "black"]
        const possibleValuesWithItalics = [...possibleValues.map(value => `${value} italic`), ...possibleValues]
        const lowerCaseFamily = family.toLowerCase()
        const tokens = [...lowerCaseFamily.split(" "), ...lowerCaseFamily.split("-"), ...lowerCaseFamily.split("_")]
        const foundToken = possibleValuesWithItalics.find(value => tokens.includes(value) || tokens.includes(value.replace(/\s+/g, "")))

        // Return found token with each letter capitalized
        if (foundToken) return foundToken.replace(/(^\w|\s\w)/g, char => char.toUpperCase())

        return "Regular"
    }

    createTypeface(family: string): Typeface {
        const existingTypeface = this.byFamily.get(family)
        if (existingTypeface) return existingTypeface

        const typeface: Typeface = {
            source: this.name,
            family,
            fonts: [],
        }

        this.addTypeface(typeface)
        return typeface
    }

    private addTypeface(typeface: Typeface) {
        this.typefaces.push(typeface)
        this.byFamily.set(typeface.family, typeface)
    }

    public parseSelector(selector: string): TypefaceLocator | null {
        if (!selector.startsWith(customFontSelectorPrefix)) return null

        const tokens = selector.split(customFontSelectorPrefix)
        const locator: TypefaceLocator = { source: "custom", family: tokens[1] }
        return locator
    }

    setSelectorLoaded(selector: string) {
        const locator = this.parseSelector(selector)
        if (!locator) return null

        const typeface: Typeface = { ...this.getTypefaceByFamily(locator.family) as Typeface }

        typeface.fonts[0].status = "loaded"

        this.byFamily.set(typeface.family, typeface)
    }

    getFontBySelector(selector: string, createFont = true): ReadonlyFont | null {
        const locator = this.parseSelector(selector)
        if (!locator) return null
        if (!createFont && !this.byFamily.get(locator.family)) return null

        return this.getTypefaceByFamily(locator.family).fonts[0]
    }
    getTypefaceByFamily(family: string): ReadonlyTypeface {
        const foundTypeface = this.byFamily.get(family)
        if (foundTypeface) return foundTypeface

        const typeface: Typeface = {
            source: "custom",
            family,
            fonts: [],
        }
        typeface.fonts.push({ selector: `${customFontSelectorPrefix}${family}`, variant: this.inferVariantName(family), typeface })
        return typeface
    }
}
