import { defaultFontSelector } from "./fonts"
import { googleFontSelectorPrefix, GoogleFontSource } from "./GoogleFontSource"
import { LocalFontSource } from "./LocalFontSource"
import {
    ReadonlyFont,
    Font,
    WebFontLocator,
    FontVariant,
    Typeface,
    TypefaceLocator,
    TypefaceSourceNames,
    TypefaceSourceName,
    ReadonlyTypeface,
    FontMetadata,
    CustomFontAssetData,
} from "./types"
import { parseVariant } from "./utils"
import { CustomFontSource, customFontSelectorPrefix } from "./CustomFontSource"
import FontFaceObserver from "fontfaceobserver"

/**
 * Stores all available fonts, whether they are currently loaded or not
 * Provides APIs to import, add and resolve fonts and font selectors
 * Model:
 * `FontStore` (single instance available via `fontStore`)
 *   `FontSource` (local/google)
 *     `Typeface` (font family and its variants)
 *       `Font` (font family with a specific variant)
 * Every `Font` has a `selector` (string), which is a unique identifier of a font
 * Google web fonts provide consistent naming for fonts,
 * so it's also possible to `parseFontSelector()` and get some info about a web font from only its selector
 *
 * @internal
 */
export class FontStore {
    private bySelector = new Map<string, ReadonlyFont>()
    private allFonts: ReadonlyFont[] = []
    readonly defaultFont: ReadonlyFont

    constructor() {
        this.local = new LocalFontSource()
        this.google = new GoogleFontSource()
        this.custom = new CustomFontSource()

        this.bySelector = new Map<string, ReadonlyFont>()
        this.importFonts(TypefaceSourceNames.Local)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.defaultFont = this.getFontBySelector(defaultFontSelector)!
    }

    local: LocalFontSource
    google: GoogleFontSource
    custom: CustomFontSource

    importFonts(source: TypefaceSourceNames.Google, fonts: google.fonts.WebfontFamily[]): void
    importFonts(source: TypefaceSourceNames.Local): void
    importFonts(source: TypefaceSourceNames.Custom, fontAssetMap: Map<string, CustomFontAssetData>): void
    importFonts(
        source: TypefaceSourceNames,
        fonts?: google.fonts.WebfontFamily[] | string[] | Map<string, CustomFontAssetData>
    ): void {
        if (source === TypefaceSourceNames.Google) {
            const results = this.google.importFonts(fonts as google.fonts.WebfontFamily[])
            results.forEach(font => this.createWebFontIfNeeded(font))
        } else if (source === TypefaceSourceNames.Local) {
            const results = this.local.importFonts()
            results.forEach(font => this.addFont(font))
        } else if (source === TypefaceSourceNames.Custom) {
            // Clear custom fonts from the list as they could be deleted from assets
            this.bySelector.forEach((_, key) => {
                if (key.startsWith(customFontSelectorPrefix)) {
                    this.bySelector.delete(key)
                }
            })
            const results = this.custom.importFonts(fonts as Map<string, CustomFontAssetData>)
            results.forEach(font => this.addFont(font))
        }

        this.allFonts.length = 0
        // Keep the same instance of the allFonts array to prevent rerendering
        this.allFonts.push(...Array.from(this.bySelector.values()))
    }

    private addFont(font: Font) {
        this.bySelector.set(font.selector, font)
    }

    private getSource(sourceName: TypefaceSourceName): LocalFontSource | GoogleFontSource | CustomFontSource {
        return this[sourceName]
    }

    getTypeface(info: TypefaceLocator): ReadonlyTypeface | null {
        const source = this.getSource(info.source)
        const typeface = source.getTypefaceByFamily(info.family)
        return typeface
    }

    private getFontSelector(locator: WebFontLocator): string | null {
        const { family, variant, source } = locator
        if (source === TypefaceSourceNames.Local) {
            const typeface = this.getTypeface(locator)
            if (!typeface) return null
            const info = typeface.fonts.find(t => t.variant === variant)
            if (!info) return null
            return info.selector
        }
        return `GF;${family}-${variant}`
    }

    getFontBySelector(selector: string, createFont = true): ReadonlyFont | null {
        if (selector.startsWith(customFontSelectorPrefix)) {
            return this.custom.getFontBySelector(selector, createFont)
        }
        return this.bySelector.get(selector) || null
    }

    getOrCreateFontBySelector(selector: string): ReadonlyFont | null {
        let font = this.getFontBySelector(selector)
        if (font) return font

        // We can only create google fonts from a selector for now
        const locator = this.google.parseSelector(selector)
        if (!locator) return null
        font = this.createWebFontIfNeeded(locator)
        return font
    }

    getAvailableFonts(): ReadonlyFont[] {
        return this.allFonts
    }

    private createTypefaceIfNeeded(locator: TypefaceLocator): ReadonlyTypeface {
        let typeface = fontStore.getTypeface(locator) as Typeface | null
        if (!typeface) {
            const source = this.getSource(locator.source)
            typeface = source.createTypeface(locator.family)
        }
        return typeface
    }

    createWebFontIfNeeded = (locator: WebFontLocator): ReadonlyFont => {
        const { source, family, variant } = locator
        const typeface = this.createTypefaceIfNeeded({ source, family }) as Typeface
        let font = typeface.fonts.find(t => t.variant === locator.variant)
        if (!font) {
            const variantInfo: Partial<FontVariant> = parseVariant(variant) || {}
            const { weight, style } = variantInfo
            const selector = this.getFontSelector(locator) || ""
            if (!selector) {
                // tslint:disable-next-line:no-console
                console.warn("Invalid font locator", locator)
            }
            font = {
                typeface,
                variant,
                selector,
                weight,
                style,
            }
            typeface.fonts.push(font)
            this.addFont(font)
        }
        return font
    }

    isSelectorLoaded(selector: string): boolean {
        const font = this.getFontBySelector(selector)
        return (font && font.status === "loaded") || false
    }

    /** We can only load google webfonts, or the Inter local font (Inter/Inter-Bold/etc...) **/
    canLoadSelector(selector: string): boolean {
        // google web fonts can get loaded using google font loader
        if (selector.startsWith(googleFontSelectorPrefix)) return true

        // load custom font
        if (selector.startsWith(customFontSelectorPrefix)) return true

        // Inter font is always defined in css (inter.css), we still need to use CSS Font API (when supported)
        // to wait until browser actually loads it
        if (this.local.interTypefaceSelectors.has(selector) && document.fonts) return true

        return false
    }

    async loadWebFont(sourceName: TypefaceSourceName, family: string, variants?: string[]): Promise<ReadonlyFont[]> {
        const fonts: ReadonlyFont[] = []
        const source = this.getSource(sourceName)
        if (source instanceof LocalFontSource) return fonts
        if (source instanceof CustomFontSource) return fonts
        await source.loadWebFont(family, variants, locator => {
            const font = this.createWebFontIfNeeded(locator) as Font
            font.status = "loaded"
            fonts.push(font)
            return font
        })
        return fonts
    }

    // TODO: Review if we need this method. It doesn't really load the font,
    // it only waits until it's ready in the document, which will never be the
    // case on Desktop for example.
    private async loadCustomFont(font: ReadonlyFont) {
        const observer = new FontFaceObserver(font.typeface.family)
        try {
            await observer.load(null, 5000)
            this.custom.setSelectorLoaded(font.selector)
        } catch (e) {
            // In case the font doesn't load within 5 seconds, fail gracefully
        }
    }

    private async loadCSSFont(font: Font) {
        const cssFont = `${font.style ?? ""} ${font.weight ?? ""} 20px ${font.typeface.family}`
        if (document.fonts.check(cssFont)) return
        await document.fonts.load(cssFont)
        font.status = "loaded"
    }

    private async loadWebFontFromSelector(selector: string): Promise<ReadonlyFont[]> {
        if (this.isSelectorLoaded(selector)) return []
        if (!this.canLoadSelector(selector)) return []

        // Load google fonts
        const parsed = this.google.parseSelector(selector)
        if (parsed) {
            const fonts = await this.loadWebFont(TypefaceSourceNames.Google, parsed.family, [parsed.variant])
            return fonts
        }

        // Load custom fonts
        const customFont = this.custom.getFontBySelector(selector)
        if (customFont) {
            await this.loadCustomFont(customFont)

            return [customFont]
        }

        // Load local CSS font
        const font = this.getFontBySelector(selector)
        if (!font) return []
        await this.loadCSSFont(font as Font)
        return [font]
    }

    async loadWebFontsFromSelectors(selectors: string[]): Promise<ReadonlyFont[]> {
        const fonts: ReadonlyFont[] = []
        // TODO: Using Promise.all() resulted in a lost promise, check why
        // TODO: Consider loading all in parallel, but maybe without Promise.all()
        for (const selector of selectors) {
            const list = await this.loadWebFontFromSelector(selector)
            fonts.push(...list)
        }
        return fonts
    }
}

/** @internal */
export const fontStore = new FontStore()
