import { Action, ActionControls } from "../render/types/Action"
import { warnOnce } from "@framerjs/shared"
// AssetResolver must be imported as a relative path
// to prevent it from being included in the framer.api.md imports list as a module.
import { AssetResolver } from "../../../Assets/src"
import { ComponentLoader } from "../render/componentLoader"
import { LayoutMetrics } from "../render/types/LayoutMetrics"

/**
 * The Runtime lives in ./Source/Runtime and implements Vekter-specific
 * functionality. Because the implementation of this functionality might change,
 * we don't want to make it part of Framer Library. Some functions, however, are
 * exposed to the user as exports from `"framer"`.
 *
 * This file provides a way for the runtime to inject the functionality into
 * Framer Library. This should make it easier to keep Vekter compatible with
 * multiple Framer Library versions.
 */

/**
 * This interface contains the functions injected by the runtime. Adding a new
 * function to this interface is fine. but:
 *
 * BE CAREFUL UPDATING THE TYPES OF EXISTING FUNCTIONS
 *
 * Because these types are only checked at compile time, they only can provide a
 * guarantee that old versions of the Library will keep working if they don't
 * change. If you do need to change existing types, the runtime needs to make
 * sure it provides backwards compatibily for old versions of Framer Library.
 * @internal
 */
export interface Runtime {
    addActionControls<Options extends { [key: string]: any }>(
        action: Action<Options>,
        title: string,
        controls: ActionControls<Options>
    ): void
    assetResolver: AssetResolver
    componentLoader: ComponentLoader
    publishLayoutChange(change: { nodeId: string; layoutMetrics: LayoutMetrics }): void
}

const mockWithWarning = (message: string) => {
    return () => {
        warnOnce(message)
    }
}

/** This stores the injected implementations */
const implementation = {}
let isRuntimeInjected = false

const runtimeProxy: ProxyHandler<Runtime> = {
    get(target, key, reciever) {
        if (Reflect.has(target, key)) {
            return Reflect.get(target, key, reciever)
        }
        if (isRuntimeInjected) {
            return mockWithWarning(`${String(key)} is not available in this version of Framer.`)
        } else {
            return mockWithWarning(`${String(key)} is only available inside of Framer. https://www.framer.com/`)
        }
    },
}

/**
 * This proxy makes sure that any key on the runtime object will return a
 * function that logs a warning to the console. Functions for which a
 * implementation is provided are available through this object, e.g.
 * `runtime.addActionControls()`
 * @internal
 */
export const runtime: Runtime = new Proxy(implementation as Runtime, runtimeProxy)

/**
 * This function is used by the `initializeRuntime()` function of the runtime to
 * provide the implementation of the functions defined in the `Runtime`
 * interface.
 * @internal
 */
export function _injectRuntime(injectedRuntime: Partial<Runtime>) {
    Object.assign(implementation, injectedRuntime)
    isRuntimeInjected = true
}
