// Based on https://github.com/ZeeCoder/react-resize-observer/blob/master/src/index.js
import ResizeObserverPoly from "resize-observer-polyfill"
import { useEffect, RefObject } from "react"

// @ts-ignore ResizeObserver type definitions are incorrect: https://github.com/que-etc/resize-observer-polyfill/pull/52
const ResizeObserver: typeof ResizeObserverPoly = ResizeObserverPoly

// Jest tests fail when the resize observer runs
let resizeObserverIgnored = false

/**
 * A hook to observe the size changes of an HTML element.
 *
 * Uses native ResizeObserver if available, a polyfill otherwise.
 *
 * @param ref - A ref to the html element to observe
 * @param onResize - The function to call when the size of the element changes
 * @param skipHook - If false this hook is disabled. Defaults to false
 * @param observeChildren - If true, not the element, but it's children are observed
 */
export function useResizeObserver(
    ref: RefObject<HTMLElement>,
    onResize: () => void,
    options: {
        skipHook?: boolean
        observeChildren?: boolean
    } = {}
) {
    const { skipHook = false, observeChildren = false } = options
    useEffect(() => {
        if (skipHook || !ref.current || resizeObserverIgnored) {
            return
        }
        const observer = new ResizeObserver(onResize)
        if (observeChildren) {
            for (const child of ref.current.children) {
                observer.observe(child)
            }
        } else {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, skipHook, observeChildren, onResize])
}

export function setIgnoreResizeObserver(ignore: boolean) {
    resizeObserverIgnored = ignore
}
