import * as React from "react"
import { forwardRef, Ref } from "react"
import { Rect } from "../../types/Rect"
import { Size } from "../../types/Size"
import { WithEvents, WithEventsProperties } from "../../../components/hoc/WithEvents"
import { DeprecatedFrame, DeprecatedCoreFrameProps } from "./DeprecatedFrame"
import { FrameWithMotion, FrameProps } from "./FrameWithMotion"
import { useParentSize, deprecatedParentSize } from "../../types/NewConstraints"
import { isDeprecatedFrameProps } from "./isDeprecatedFrameProps"
export { isDeprecatedFrameProps } from "./isDeprecatedFrameProps"
// Re-exports
export { DeprecatedFrame } from "./DeprecatedFrame"
export type { DeprecatedCoreFrameProps, DeprecatedFrameProperties } from "./DeprecatedFrame"
export { FrameWithMotion } from "./FrameWithMotion"
export type { BackgroundImageState, FrameProps } from "./FrameWithMotion"
export type { BaseFrameProps, FrameLayoutProperties, CSSTransformProperties, VisualProperties } from "./types"

// Public types
/** @public */
export type DeprecatedFrameWithEventsProps = DeprecatedCoreFrameProps & WithEventsProperties
/** @public */
export const DeprecatedFrameWithEvents: React.ComponentClass<Partial<DeprecatedFrameWithEventsProps>> = WithEvents(
    DeprecatedFrame
)

// const isPreview = RenderEnvironment.target === RenderTarget.preview

// We need switcher component to useContext without conditions
// THIS SHOULD NOT BE USED DIRECTLY IN LIBRARY NOR IN VEKTER
// Only for backwards compatibility
/** @public */
// tslint:disable-next-line:no-shadowed-variable
export const Frame = forwardRef(function Frame(props: Partial<FrameProps>, ref: Ref<HTMLDivElement>) {
    const parentSize = useParentSize()
    if (isDeprecatedFrameProps(props)) {
        const currentParentSize: Size | null = props.parentSize || deprecatedParentSize(parentSize)

        // We use here DeprecatedFrame WithEvents for simplicity
        return <DeprecatedFrameWithEvents {...props} parentSize={currentParentSize} />
    }
    return <FrameWithMotion {...props} ref={ref} />
})
;(Frame as any).rect = (props: Partial<FrameProps>, parentSize?: Size): Rect | null => {
    if (isDeprecatedFrameProps(props)) {
        return DeprecatedFrame.rect(props)
    }
    return FrameWithMotion.rect(props)
}
