import isPropValid from "@emotion/is-prop-valid"
import { isValidMotionProp, motion, MotionStyle, resolveMotionValue } from "framer-motion"
import * as React from "react"
import { forwardRef, Ref } from "react"
import { Omit } from "../../../utils/omit"
import { safeWindow } from "../../../utils/safeWindow"
import { Border } from "../../style/BorderComponent"
import { collectBackgroundImageFromProps } from "../../style/collectBackgroundImageFromProps"
import { BackgroundProperties } from "../../traits/Background"
import {
    calculateRect,
    ConstraintConfiguration,
    constraintsEnabled,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    useConstraints,
    useProvideParentSize,
} from "../../types/NewConstraints"
import { Rect } from "../../types/Rect"
import { RenderTarget, RenderEnvironment } from "../../types/RenderEnvironment"
import { Size } from "../../types/Size"
import { injectComponentCSSRules } from "../../utils/injectComponentCSSRules"
import { processOverrideForwarding } from "../../utils/processOverrideForwarding"
import { transformValues } from "../../utils/transformCustomValues"
import { Layer, LayerProps } from "../Layer"
import { getStyleForFrameProps, hasLeftAndRight, hasTopAndBottom } from "./getStyleForFrameProps"
import {
    BaseFrameProps,
    CSSTransformProperties,
    FrameLayoutProperties,
    MotionDivProps,
    VisualProperties,
} from "./types"
import { useLayoutId } from "../../utils/useLayoutId"
import { CustomMotionProps } from "../../../animation/Motion/types"
import { transformTemplate } from "../../utils/transformTemplate"
import { useResourceLoading } from "../ResourceLoading"
import { minZoomForPixelatedImageRendering, imageRenderingForZoom } from "../../utils/imageRendering"

export interface BackgroundImageState {
    currentBackgroundImageSrc: string | null
}

function hasEvents(props: Partial<FrameProps>) {
    for (const key in props) {
        if (
            key === "drag" ||
            key.startsWith("while") ||
            (typeof props[key] === "function" && key.startsWith("on") && !key.includes("Animation"))
        ) {
            return true
        }
    }

    return false
}
const pointerEvents = [
    "onAuxClick",
    "onClick",
    "onDoubleClick",
    "onMouse",
    "onMouseDown",
    "onMouseUp",
    "onTapDown",
    "onTap",
    "onTapUp",
    "onPointer",
    "onPointerDown",
    "onPointerUp",
    "onTouch",
    "onTouchDown",
    "onTouchUp",
]
const pointerEventsSet = new Set([
    ...pointerEvents,
    ...pointerEvents.map(event => `${event}Capture`), // Add capture event variants
])

function getCursorFromEvents(props: Partial<FrameProps>) {
    if (props.drag) {
        return "grab"
    }

    for (const key in props) {
        if (pointerEventsSet.has(key)) {
            return "pointer"
        }
    }

    return undefined
}

export function unwrapFrameProps(
    frameProps: Partial<FrameLayoutProperties & ConstraintConfiguration>
): Partial<NewConstraintProperties> {
    const { left, top, bottom, right, width, height, center, _constraints, size } = frameProps
    const constraintProps: Partial<NewConstraintProperties> = {
        top: resolveMotionValue(top),
        left: resolveMotionValue(left),
        bottom: resolveMotionValue(bottom),
        right: resolveMotionValue(right),
        width: resolveMotionValue(width),
        height: resolveMotionValue(height),
        size: resolveMotionValue(size),
        center,
        _constraints,
    }
    return constraintProps
}

/** @public */
export interface FrameProps
    extends BackgroundProperties,
        VisualProperties,
        Omit<MotionDivProps, "color">,
        CSSTransformProperties,
        LayerProps,
        FrameLayoutProperties,
        ConstraintConfiguration,
        BaseFrameProps,
        CustomMotionProps {
    /**@internal */
    __layoutId?: string | undefined
}

export const defaultFrameRect = { x: 0, y: 0, width: 200, height: 200 }

function useStyleAndRect(props: Partial<FrameProps>, dataProps: Record<string, unknown>): [MotionStyle, Rect | null] {
    injectComponentCSSRules()
    const { style, _initialStyle, size } = props
    const unwrappedProps = unwrapFrameProps(props)
    const constraintsRect = useConstraints(unwrappedProps)

    const backgroundStyle: React.CSSProperties = {}
    const backgroundImage = collectBackgroundImageFromProps(props, constraintsRect || undefined, backgroundStyle)

    if (backgroundImage && constraintsRect) {
        const width = backgroundImage.pixelWidth ?? backgroundImage.intrinsicWidth ?? 0
        const height = backgroundImage.pixelHeight ?? backgroundImage.intrinsicHeight ?? 0

        // Set appropriate image rendering for the current environment
        const devicePixelRatio = RenderTarget.current() === RenderTarget.canvas ? safeWindow.devicePixelRatio || 1 : 1
        const minPixelatedZoom = minZoomForPixelatedImageRendering(
            constraintsRect,
            backgroundImage.fit,
            width,
            height,
            devicePixelRatio
        )

        if (RenderTarget.current() === RenderTarget.canvas) {
            // On the canvas we want to always keep image-rendering stable during zoom (hence the zoom = 1) the canvas pixelates the images on zooming in with a css rule
            backgroundStyle.imageRendering = imageRenderingForZoom(1, minPixelatedZoom)
        } else {
            // In the preview or with export we might require a higer zoom level where images need to get pixelated if their frame is larger then their intrinsic size
            backgroundStyle.imageRendering = imageRenderingForZoom(RenderEnvironment.zoom, minPixelatedZoom)
        }
    }

    const defaultStyle: MotionStyle = {
        display: "block",
        flexShrink: 0,
        userSelect: "none",
        // XXX: this is hack until we find a better solution
        backgroundColor: props.background === undefined ? "rgba(0, 170, 255, 0.3)" : undefined,
    }

    useResourceLoading(async () => {
        if (!backgroundStyle.backgroundImage) return

        const match = /^url\("(.*?)"\)/.exec(backgroundStyle.backgroundImage)
        if (!match) return

        const img = new Image()
        img.src = match[1]

        await new Promise((resolve, reject) => {
            img.onload = resolve
            img.onerror = reject
        })
    })

    if (!hasEvents(props)) {
        defaultStyle.pointerEvents = "none"
    }

    const addTextCentering =
        React.Children.count(props.children) > 0 &&
        React.Children.toArray(props.children).every(child => {
            return typeof child === "string" || typeof child === "number"
        })
    const centerTextStyle = addTextCentering && {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    }

    const propsStyle = getStyleForFrameProps(props)

    if (size === undefined) {
        if (!hasLeftAndRight(propsStyle)) {
            defaultStyle.width = defaultFrameRect.width
        }

        if (!hasTopAndBottom(propsStyle)) {
            defaultStyle.height = defaultFrameRect.height
        }
    }

    let constraintsStyle: MotionStyle = {}

    if (constraintsRect && constraintsEnabled(unwrappedProps)) {
        constraintsStyle = {
            left: constraintsRect.x,
            top: constraintsRect.y,
            width: constraintsRect.width,
            height: constraintsRect.height,
            right: undefined,
            bottom: undefined,
        }

        if (RenderTarget.current() === RenderTarget.canvas) {
            constraintsStyle = {
                ...constraintsStyle,
                left: 0,
                top: 0,
                // Framer Motion has an optimization where it will not set the transform if it is 0,
                // Setting the x or y to 0px works around that so transform is always set
                x: constraintsRect.x === 0 ? "0px" : constraintsRect.x,
                y: constraintsRect.y === 0 ? "0px" : constraintsRect.y,
            }
        }
    }

    // In theory we should not have constraints and props styles at the same time
    // because we use constraints internally in vekter and top level props are only for usage from customer code
    //
    // In practice we have it with code overrides
    // But we take `propsStyle` priority in any case now
    Object.assign(defaultStyle, backgroundStyle, centerTextStyle, _initialStyle, propsStyle, constraintsStyle, style)

    Layer.applyWillChange(props, defaultStyle, true)

    let resultStyle = defaultStyle
    if (!defaultStyle.transform) {
        // Reset the transform explicitly, because Framer Motion will not treat undefined values as 0 and still generate a transform
        resultStyle = { x: 0, y: 0, ...defaultStyle }
    }

    return [resultStyle, constraintsRect]
}

// These properties are considered valid React DOM props because they're valid
// SVG props, so we need to manually exclude them.
const filteredProps = new Set([
    "width",
    "height",
    "opacity",
    "overflow",
    "radius",
    "background",
    "color",
    "x",
    "y",
    "z",
    "rotate",
    "rotateX",
    "rotateY",
    "rotateZ",
    "scale",
    "scaleX",
    "scaleY",
    "skew",
    "skewX",
    "skewY",
    "originX",
    "originY",
    "originZ",
])

// Remove in 2.0
const customMotionProps = new Set(["positionTransition", "layoutTransition"])
function isCustomMotionProp(key: string) {
    return customMotionProps.has(key)
}

function getMotionProps(props: Partial<FrameProps>): MotionDivProps {
    const motionProps = {}

    for (const key in props) {
        const isValid = isValidMotionProp(key) || isPropValid(key) || isCustomMotionProp(key)
        if (isValid && !filteredProps.has(key)) {
            motionProps[key] = props[key]
        }
    }

    return motionProps
}
interface RectProviding {
    rect(props: Partial<FrameProps>, parentSize?: Size | null): Rect | null
}

/** @internal */
export const FrameWithMotion: RectProviding &
    React.ForwardRefExoticComponent<
        React.PropsWithoutRef<Partial<FrameProps>> & React.RefAttributes<HTMLDivElement>
    > = Object.assign(
    // tslint:disable-next-line:no-shadowed-variable
    forwardRef(function FrameWithMotion(props: Partial<FrameProps>, ref: Ref<HTMLDivElement>) {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
        const { visible = true } = props
        if (!visible) {
            return null
        }
        return <VisibleFrame {...props} ref={ref} />
    }),
    {
        rect(props: Partial<FrameProps>, parentSize?: Size): Rect | null {
            return calculateRect(unwrapFrameProps(props), parentSize || ParentSizeState.Unknown)
        },
    }
)

// tslint:disable-next-line:no-shadowed-variable
const VisibleFrame = forwardRef(function VisibleFrame(props: Partial<FrameProps>, ref: Ref<HTMLDivElement>) {
    const { _border, name, center, border } = props
    const { props: propsWithOverrides, children } = processOverrideForwarding(props)
    const motionProps = getMotionProps(propsWithOverrides)
    const layoutId = useLayoutId(props)
    const cursor = getCursorFromEvents(props)

    const dataProps = {
        "data-framer-component-type": "Frame",
        "data-framer-name": name,
        "data-framer-cursor": cursor,
        "data-framer-highlight": cursor === "pointer" ? true : undefined,
        "data-layoutid": layoutId,
    }

    const [currentStyle, rect] = useStyleAndRect(propsWithOverrides, dataProps)

    if (center && !(rect && constraintsEnabled(unwrapFrameProps(propsWithOverrides)))) {
        motionProps.transformTemplate = transformTemplate(center)
    }

    const parentSize: ParentSize = rect ? { width: rect.width, height: rect.height } : ParentSizeState.Disabled

    const wrappedContent = useProvideParentSize(
        <>
            {children}
            <Border {..._border} border={border} layoutId={layoutId} />
        </>,
        parentSize
    )

    return (
        <motion.div
            {...dataProps}
            {...motionProps}
            layoutId={layoutId}
            style={currentStyle}
            ref={ref}
            transformValues={transformValues}
        >
            {wrappedContent}
        </motion.div>
    )
})
