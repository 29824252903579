import * as React from "react"

export type ReactElementType = string | React.JSXElementConstructor<any>

function isEmpty(obj: any): boolean {
    return !obj || (!Object.keys(obj).length && obj.constructor === Object)
}

export function isReactElement<P, T extends ReactElementType = ReactElementType>(
    test: React.ReactChild
): test is React.ReactElement<P, T> {
    return typeof test !== "string" && typeof test !== "number"
}

export function isReactChild(test: React.ReactNode): test is React.ReactChild {
    return test !== null && typeof test !== "undefined" && typeof test !== "boolean" && !isEmpty(test)
}

export function assertNever(x: never, error?: any): never {
    throw error || new Error("Unexpected object: " + x)
}
