import { useRef, useCallback, RefObject } from "react"
import { PanInfo, MotionValue, useDomEvent, Point2D } from "framer-motion"
import { debounce } from "../../render/utils/debounce"
import { clamp } from "../../utils/math"

interface WheelScrollOptions {
    enabled: boolean
    initial: Point2D
    prev: Point2D
    direction: "horizontal" | "vertical" | "both"
    constraints: RefObject<{ top: number; right: number; bottom: number; left: number }>
    offsetX: MotionValue<number>
    offsetY: MotionValue<number>
    onScrollStart: ((info: PanInfo) => void) | undefined
    onScroll: ((info: PanInfo) => void) | undefined
    onScrollEnd: ((info: PanInfo) => void) | undefined
}

export function useWheelScroll(
    ref: RefObject<Element>,
    {
        enabled,
        initial,
        prev,
        direction,
        constraints,
        offsetX,
        offsetY,
        onScrollStart,
        onScroll,
        onScrollEnd,
    }: WheelScrollOptions
) {
    const isWheelScrollActive = useRef(false)

    const getPointData = useCallback(() => {
        const point = getPoint(offsetX, offsetY)

        const data = {
            point,
            velocity: { x: offsetX.getVelocity(), y: offsetY.getVelocity() },
            offset: { x: point.x - initial.x, y: point.y - initial.y },
            delta: { x: point.x - prev.x, y: point.y - prev.y },
        }

        prev.x = point.x
        prev.y = point.y

        return data
    }, [scrollX, scrollY])

    let handler: EventListener | undefined
    if (enabled) {
        function clampX(v: number) {
            return constraints.current === null ? v : clamp(v, constraints.current.left, constraints.current.right)
        }

        function clampY(v: number) {
            return constraints.current === null ? v : clamp(v, constraints.current.top, constraints.current.bottom)
        }

        function updateX(delta: number) {
            offsetX.stop()
            offsetX.set(clampX(offsetX.get() - delta))
        }

        function updateY(delta: number) {
            offsetY.stop()
            offsetY.set(clampY(offsetY.get() - delta))
        }

        const debouncedOnScrollEnd = debounce(() => {
            onScrollEnd && onScrollEnd(getPointData())
            isWheelScrollActive.current = false
        }, 200)

        handler = (e: WheelEvent) => {
            e.preventDefault()

            if (!isWheelScrollActive.current) {
                const x = offsetX.get()
                const y = offsetY.get()
                initial.x = x
                initial.y = y
                prev.x = x
                prev.y = y
                onScrollStart && onScrollStart(getPointData())
                isWheelScrollActive.current = true
            }

            switch (direction) {
                case "horizontal":
                    updateX(e.deltaX)
                    break
                case "vertical":
                    updateY(e.deltaY)
                    break
                default:
                    updateX(e.deltaX)
                    updateY(e.deltaY)
            }

            onScroll && onScroll(getPointData())
            debouncedOnScrollEnd()
        }
    }

    // Appending event directly to DOM as React doesn't have an API for non-passive wheel events.
    useDomEvent(ref, "wheel", handler, { passive: false })
}

function getPoint(x: MotionValue<number>, y: MotionValue<number>): Point2D {
    return { x: x.get(), y: y.get() }
}
