import { RenderTarget, RenderEnvironment } from "../types/RenderEnvironment"
// AssetContext and parseAssetReference must be imported as a relative path
// to prevent it from being included in the framer.api.md imports list as a module.
import { AssetContext } from "../../../../Assets/src/types/AssetResolver"
import { parseAssetReference } from "../../../../Assets/src/assetReference"

// This should be moved to the runtime, but currently is too dependant on
// RenderTarget and RenderEnvironment (which should also be moved to the
// runtime)

function imagePathForSize(imageName: string, size: number | undefined): string {
    // don't apply cache size for third party images
    // HOTFIX. Proper fix: regenerate sizes for third party images
    const cacheDir = size === undefined || imageName.startsWith("node_modules") ? "" : `../../.cache/images/${size}/`
    return cacheDir + imageName
}

function resolveImagePath({
    imagePath,
    imageBaseURL,
    target,
    relativeToProjectRoot = false,
}: {
    imagePath: string
    imageBaseURL: string
    target: RenderTarget
    relativeToProjectRoot?: boolean
}): string {
    // absolute paths
    if (imagePath.startsWith("http://") || imagePath.startsWith("https://") || imagePath.startsWith("file://")) {
        return imagePath
    }

    let baseURL = imageBaseURL.replace(/\/$/, "")

    if (relativeToProjectRoot || imagePath.startsWith("node_modules/")) {
        // make sure baseURL is NOT relative to design/images
        baseURL = baseURL.replace(/\/design\/images$/, "")
    } else {
        // make sure baseURL IS relative to design/images
        if (!baseURL.endsWith("/design/images")) {
            baseURL += "/design/images"
        }
        // ... and that we won't end up with design/images/design/images
        if (imagePath.startsWith("design/images/")) {
            imagePath = imagePath.replace(/^design\/images\//, "")
        }
    }

    if (target === RenderTarget.export) {
        return `##base64-${baseURL}/${imagePath}##`
    }
    return `${baseURL}/${imagePath}`
}

/**
 * @internal
 * @param reference
 * @param context
 */
export function desktopAssetResolver(reference: string | undefined, context: AssetContext): string | undefined {
    if (!reference) {
        return undefined
    }

    // "resource URLs" must resolve relative to the project root
    // - this is the "url" function from framer/resource (which is actually the
    //   "serverURL" function from ./serverURL.ts)
    // - or old code components with file props, which are "design/assets/xxx"
    let relativeToProjectRoot = context.isFramerResourceURL

    let path: string
    const details = parseAssetReference(reference)
    if (details) {
        const { identifier, packageIdentifier } = details
        if (packageIdentifier) {
            path = `node_modules/${packageIdentifier}/${identifier}`
        } else {
            path = identifier
        }
        // asset references always resolve relative to design/images
        relativeToProjectRoot = false
    } else {
        path = reference
    }

    const { imageBaseURL } = RenderEnvironment
    const target = RenderTarget.current()
    const { size } = context
    const imagePath = imagePathForSize(path, size)
    const resolvedImage = resolveImagePath({
        imagePath,
        imageBaseURL,
        target,
        relativeToProjectRoot,
    })
    return resolvedImage
}
