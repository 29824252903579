import {
    FontSource,
    TypefaceSourceName,
    Typeface,
    WebFontLocator,
    TypefaceSourceNames,
    ReadonlyTypeface,
} from "./types"
import { loadWebFont } from "./webFonts"

export const googleFontSelectorPrefix = "GF;"

/** @internal */
export class GoogleFontSource implements FontSource {
    name: TypefaceSourceName = TypefaceSourceNames.Google
    typefaces: Typeface[] = []
    byFamily = new Map<string, Typeface>()

    getTypefaceByFamily(family: string): ReadonlyTypeface | null {
        return this.byFamily.get(family) ?? null
    }

    parseSelector(selector: string): WebFontLocator | null {
        if (!selector.startsWith(googleFontSelectorPrefix)) return null
        const tokens = selector.split("-")
        if (tokens.length !== 2) return null
        const family = tokens[0].replace(googleFontSelectorPrefix, "")
        const variant = tokens[1]
        return { family, variant, source: this.name }
    }

    // TODO: these are duplicated across implementations of FontSource
    // When adding a third source, we should abstract them
    createTypeface(family: string): Typeface {
        const typeface = { family: family, fonts: [], source: this.name }
        this.addTypeface(typeface)
        return typeface
    }

    private addTypeface(typeface: Typeface) {
        this.typefaces.push(typeface)
        this.byFamily.set(typeface.family, typeface)
    }
    // end of duplication

    loadWebFont(family: string, variants?: string[], onFontLoaded?: (locator: WebFontLocator) => void): Promise<void> {
        return loadWebFont(family, variants, onFontLoaded)
    }

    importFonts(webFonts: google.fonts.WebfontFamily[]): WebFontLocator[] {
        let fontLocators: WebFontLocator[] = []
        webFonts.forEach(webFont => {
            const locators = webFont.variants.map(variant => ({
                source: this.name,
                variant: variant,
                family: webFont.family,
            }))
            fontLocators = fontLocators.concat(locators)
        })
        return fontLocators
    }
}
