import { useContext, useMemo } from "react"
import { LayoutIdContext } from "../../components/AnimateLayout/LayoutIdContext"

interface UseMagicMotionProps {
    name?: string
    id?: string
    duplicatedFrom?: string[]
    _canMagicMotion?: boolean
    __fromCodeComponentNode?: boolean
    layoutId?: string
}

/**
 * @internal
 */
export function useLayoutId(
    props: UseMagicMotionProps,
    specificLayoutId?: string,
    postfix?: string
): string | undefined {
    const { name, id, duplicatedFrom, _canMagicMotion = false, __fromCodeComponentNode = false } = props
    const { getLayoutId, enabled } = useContext(LayoutIdContext)

    return useMemo<string | undefined>(() => {
        const existingLayoutId = specificLayoutId || props.layoutId

        if ((!id && !existingLayoutId) || !enabled) return undefined
        if (!existingLayoutId && (!_canMagicMotion || __fromCodeComponentNode)) return undefined

        const layoutIdCandidate = existingLayoutId || getLayoutId({ id, name, duplicatedFrom })

        if (!layoutIdCandidate) return undefined

        const layoutId = postfix ? `${layoutIdCandidate}-${postfix}` : layoutIdCandidate

        if (!layoutId) return undefined

        return layoutId
    }, [enabled]) // Must return a stable value as layoutIds must be stable between all renders.
}
