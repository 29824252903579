import * as React from "react"

import { DeprecatedBackgroundProperties, Background } from "../traits/Background"
import { Animatable } from "../../animation/Animatable"
import { BackgroundImage } from "../types/BackgroundImage"
import { Rect } from "../types/Rect"
import { QualityOptions, setImageForFill } from "../utils/imageForFill"
import { LayerProps } from "../presentation/Layer"

function applyForwardOverrides(
    background: BackgroundImage,
    props: Partial<DeprecatedBackgroundProperties & LayerProps>
): BackgroundImage {
    const { _forwardedOverrides, id } = props
    const src = _forwardedOverrides && id ? _forwardedOverrides[id] : undefined
    if (src && typeof src === "string") {
        background = { ...background, src }
    }
    return background
}

/** @internal */
export function collectBackgroundImageFromProps(
    props: Partial<DeprecatedBackgroundProperties & LayerProps>,
    rect: Rect | undefined,
    style: React.CSSProperties
) {
    let background = Animatable.get(props.background, null)
    if (!BackgroundImage.isImageObject(background)) {
        return
    }
    background = applyForwardOverrides(background, props)
    const qualityOptions: QualityOptions = {
        frame: rect,
    }

    setImageForFill(background, qualityOptions, style)
    return background
}
