type FunctionsOnly = {
    [key: string]: () => unknown
}

type FunctionsToProperties<T extends FunctionsOnly> = {
    readonly [K in keyof T]: ReturnType<T[K]>
}

/**
 * Returns an object with properties that lazily construct their values by calling
 * the corresponding factory function exactly once. E.g. in the code below, the console
 * will log "hello world", 42, 42.
 *
 * const lazy = Lazy({
 *      value: () => {
 *          console.log("hello world")
 *          return 42
 *      }
 * })
 * console.log(lazy.value)
 * console.log(lazy.value)
 *
 *
 * Note that you also can self-reference the variable, but self-referencing factory
 * functions must specify a return type. E.g.:
 *
 * const lazy = Lazy({
 *      value: () => 42,
 *      doubleValue: (): number => 2 * lazy.value
 * })
 */
export function Lazy<Factory extends FunctionsOnly>(factory: Factory) {
    const result = {}
    for (const key in factory) {
        let value: unknown | undefined
        Object.defineProperty(result, key, {
            get() {
                if (value === undefined) {
                    value = factory[key]()
                }
                return value
            },
        })
    }
    return result as FunctionsToProperties<Factory>
}
