import * as React from "react"
import { safeWindow } from "../../utils/safeWindow"

import { Layer, LayerProps } from "./Layer"
import { Rect } from "../types/Rect"
import { Color } from "../types/Color"
import { LinearGradient } from "../types/LinearGradient"
import { RadialGradient } from "../types/RadialGradient"
import { collectOpacityFromProps } from "../traits/Opacity"
import { collectFiltersFromProps } from "../utils/filtersForNode"
import { RenderEnvironment, RenderTarget } from "../types/RenderEnvironment"
import {
    useParentSize,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    calculateRect,
    constraintsEnabled,
} from "../types/NewConstraints"
import { Shadow } from "../types/Shadow"
import { Animatable } from "../../animation/Animatable"
import { BackgroundImage } from "../types/BackgroundImage"
import { imagePatternPropsForFill } from "../utils/imagePatternPropsForFill"
import { Background } from "../traits/Background"
import { isFiniteNumber } from "../utils/isFiniteNumber"
import { FilterProperties } from "../traits/Filters"
import { BackgroundFilterProperties } from "../traits/BackdropFilters"
import { RadiusProperties } from "../traits/Radius"
import { WithOpacity } from "../traits/Opacity"
import { Size } from "../types/Size"
import { ImagePatternElement } from "./ImagePatternElement"
import { injectComponentCSSRules } from "../utils/injectComponentCSSRules"
import { elementForComponent } from "../utils/elementForComponent"
import { resetSetStyle } from "../utils/useWebkitFixes"
import {
    elementPropertiesForLinearGradient,
    elementPropertiesForRadialGradient,
} from "../utils/elementPropertiesForGradient"
import { useLayoutId } from "../utils/useLayoutId"
import { motion } from "framer-motion"
import { transformTemplate } from "../utils/transformTemplate"

/**
 * @internal
 */
export interface SVGProps
    extends Partial<NewConstraintProperties>,
        Partial<FilterProperties & BackgroundFilterProperties & RadiusProperties & WithOpacity> {
    rotation: Animatable<number> | number
    visible: boolean
    name?: string
    fill?: Animatable<Background> | Background | null
    svg: string
    intrinsicWidth?: number
    intrinsicHeight?: number
    shadows: Shadow[]
    parentSize?: ParentSize
}

/**
 * @internal
 */
export interface SVGProperties extends SVGProps, LayerProps {
    layoutId?: string | undefined
}

// Before migrating to functional components we need to get parentSize data from context
/**
 * @internal
 */
export function SVG(props: Partial<SVGProperties>): React.ReactElement<any> {
    const parentSize = useParentSize()
    const layoutId = useLayoutId(props)

    return <SVGComponent {...props} parentSize={parentSize} layoutId={layoutId} />
}

function sizeSVG(container: React.RefObject<HTMLDivElement>, props: SVGProperties) {
    const div = container.current
    const hasConstraints = constraintsEnabled(props) && props.parentSize !== ParentSizeState.Disabled
    if (hasConstraints || !div) {
        return
    }
    const svg = div.firstElementChild
    if (!svg || !(svg instanceof SVGSVGElement)) {
        return
    }
    const { intrinsicWidth, intrinsicHeight, _constraints } = props
    if (
        svg.viewBox.baseVal?.width === 0 &&
        svg.viewBox.baseVal?.height === 0 &&
        isFiniteNumber(intrinsicWidth) &&
        isFiniteNumber(intrinsicHeight)
    ) {
        svg.setAttribute("viewBox", `0 0 ${intrinsicWidth} ${intrinsicHeight}`)
    }
    // XXX TODO take the value from _constraints.aspectRatio into account
    if (_constraints && _constraints.aspectRatio) {
        svg.setAttribute("preserveAspectRatio", "")
    } else {
        svg.setAttribute("preserveAspectRatio", "none")
    }
    svg.setAttribute("width", "100%")
    svg.setAttribute("height", "100%")
}

class SVGComponent extends Layer<SVGProperties, {}> {
    static supportsConstraints = true
    static defaultSVGProps: SVGProps = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
        _constraints: {
            enabled: true,
            aspectRatio: null,
        },
        parentSize: ParentSizeState.Unknown,
        rotation: 0,
        visible: true,
        svg: "",
        shadows: [],
    }

    static readonly defaultProps: SVGProperties = {
        ...Layer.defaultProps,
        ...SVGComponent.defaultSVGProps,
    }

    static frame(props: Partial<SVGProperties>) {
        return calculateRect(props, props.parentSize || ParentSizeState.Unknown)
    }

    container: React.RefObject<HTMLDivElement> = React.createRef()

    previouslyPrefixedSVG: { svg: string; id: string }
    previouslyPrefixedSVGResult: string
    getPrefixedSVG(svg: string, id: string): string {
        if (
            this.previouslyPrefixedSVG &&
            svg === this.previouslyPrefixedSVG.svg &&
            id === this.previouslyPrefixedSVG.id
        ) {
            return this.previouslyPrefixedSVGResult
        }
        const prefixedSVG = prefixIdsInSVG(svg, id)
        this.previouslyPrefixedSVGResult = prefixedSVG
        this.previouslyPrefixedSVG = { svg, id }
        return prefixedSVG
    }

    get frame(): Rect | null {
        return calculateRect(this.props, this.props.parentSize || ParentSizeState.Unknown)
    }

    componentDidMount() {
        sizeSVG(this.container, this.props)
    }

    componentDidUpdate(prevProps: SVGProperties) {
        super.componentDidUpdate(prevProps)

        const { fill } = this.props
        if (
            BackgroundImage.isImageObject(fill) &&
            BackgroundImage.isImageObject(prevProps.fill) &&
            fill.src !== prevProps.fill.src
        ) {
            const element = elementForComponent(this)
            resetSetStyle(element, "fill", null, false)
        }
        sizeSVG(this.container, this.props)
    }

    render() {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()

        const {
            id,
            visible,
            fill,
            rotation,
            svg,
            intrinsicHeight,
            intrinsicWidth,
            width,
            height,
            layoutId,
        } = this.props
        if (!visible || !id) {
            return null
        }
        injectComponentCSSRules()
        const frame = this.frame

        // XXX find another way to not need these defaults
        const size: Size = frame || { width: intrinsicWidth || 100, height: intrinsicHeight || 100 }

        const style: React.CSSProperties = {
            imageRendering: "pixelated",
            opacity: isFiniteNumber(this.props.opacity) ? this.props.opacity : 1,
        }

        const innerStyle: React.CSSProperties = {}
        const rotate = Animatable.getNumber(rotation)
        /**
         * The if-statement below switches between positioning the SVG with
         * transforms or (in the else statement) with DOM-layout.
         *
         * On the canvas (when RenderTarget.hasRestrictions()) we want to
         * position with transforms for performance reasons. When dragging an
         * SVG around, if we can reposition an SVG using transforms, it won't
         * trigger a browser layout.
         *
         * In the preview we always position with DOM-layout, to not interfere
         * with Magic Motion that uses the transforms for animating.
         *
         * However, there might be cases where we do not have a frame to use for
         * positioning the SVG using transforms. For example when rendering
         * inside a Scroll component (that uses DOM-layout for it's children,
         * also on the canvas), we cannot always calculate a frame. In these
         * cases we do use DOM-layout to position the SVG, even on the canvas.
         */
        if (RenderTarget.hasRestrictions() && frame) {
            Object.assign(style, {
                transform: `translate(${frame.x}px, ${frame.y}px) rotate(${rotate.toFixed(4)}deg)`,
                width: `${frame.width}px`,
                height: `${frame.height}px`,
            })
            if (constraintsEnabled(this.props)) {
                style.position = "absolute"
            }
            const xFactor = frame.width / (intrinsicWidth || 1)
            const yFactor = frame.height / (intrinsicHeight || 1)

            innerStyle.transformOrigin = "top left"

            const { zoom, target } = RenderEnvironment
            if (target === RenderTarget.export) {
                const zoomFactor = zoom > 1 ? zoom : 1
                innerStyle.transform = `scale(${xFactor * zoomFactor}, ${yFactor * zoomFactor})`
                innerStyle.zoom = 1 / zoomFactor
            } else {
                innerStyle.transform = `scale(${xFactor}, ${yFactor})`
            }

            if (intrinsicWidth && intrinsicHeight) {
                innerStyle.width = intrinsicWidth
                innerStyle.height = intrinsicHeight
            }
        } else {
            const { left, right, top, bottom } = this.props
            Object.assign(style, {
                left,
                right,
                top,
                bottom,
                width,
                height,
                rotate,
            })

            Object.assign(innerStyle, {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
            })
        }

        collectOpacityFromProps(this.props, style)
        collectFiltersFromProps(this.props, style)

        Layer.applyWillChange(this.props, style, false)

        let fillElement: JSX.Element | null = null
        if (typeof fill === "string" || Color.isColorObject(fill)) {
            const fillColor = Color.isColorObject(fill) ? fill.initialValue || Color.toRgbString(fill) : fill
            style.fill = fillColor
            style.color = fillColor
        } else if (LinearGradient.isLinearGradient(fill)) {
            const gradient = fill
            // We need encodeURI() here to handle our old id's that contained special characters like ';'
            // Creating an url() entry for those id's unescapes them, so we need to use the URI encoded version
            const gradientId = `${encodeURI(id || "")}g${LinearGradient.hash(gradient)}`
            style.fill = `url(#${gradientId})`
            const elementProperties = elementPropertiesForLinearGradient(gradient, id)

            fillElement = (
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" style={{ position: "absolute" }}>
                    <linearGradient id={gradientId} gradientTransform={`rotate(${elementProperties.angle}, 0.5, 0.5)`}>
                        {elementProperties.stops.map((stop, idx) => {
                            return (
                                <stop
                                    key={idx}
                                    offset={stop.position}
                                    stopColor={stop.color}
                                    stopOpacity={stop.alpha}
                                />
                            )
                        })}
                    </linearGradient>
                </svg>
            )
        } else if (RadialGradient.isRadialGradient(fill)) {
            const gradient = fill
            // We need encodeURI() here to handle our old id's that contained special characters like ';'
            // Creating an url() entry for those id's unescapes them, so we need to use the URI encoded version
            const gradientId = `${encodeURI(id || "")}g${RadialGradient.hash(gradient)}`
            style.fill = `url(#${gradientId})`
            const elementProperties = elementPropertiesForRadialGradient(gradient, id)
            fillElement = (
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" style={{ position: "absolute" }}>
                    <radialGradient
                        id={gradientId}
                        cy={gradient.centerAnchorY}
                        cx={gradient.centerAnchorX}
                        r={gradient.widthFactor}
                    >
                        {elementProperties.stops.map((stop, idx) => {
                            return (
                                <stop
                                    key={idx}
                                    offset={stop.position}
                                    stopColor={stop.color}
                                    stopOpacity={stop.alpha}
                                />
                            )
                        })}
                    </radialGradient>
                </svg>
            )
        } else if (BackgroundImage.isImageObject(fill)) {
            const imagePattern = imagePatternPropsForFill(fill, size, id)
            if (imagePattern) {
                style.fill = `url(#${imagePattern.id})`
                fillElement = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="100%"
                        height="100%"
                        style={{ position: "absolute" }}
                    >
                        <defs>
                            <ImagePatternElement {...imagePattern} />
                        </defs>
                    </svg>
                )
            }
        }
        const dataProps = {
            "data-framer-component-type": "SVG",
        }

        const content = (
            <>
                {fillElement}
                <div
                    key={BackgroundImage.isImageObject(fill) ? fill.src : ""} // Webkit doesn't update when a new image is set
                    className={"svgContainer"}
                    style={innerStyle}
                    ref={this.container}
                    dangerouslySetInnerHTML={{ __html: this.getPrefixedSVG(svg, id) }}
                />
            </>
        )

        return (
            <motion.div
                {...dataProps}
                layoutId={layoutId}
                transformTemplate={!frame ? transformTemplate(this.props.center) : undefined}
                id={id}
                style={style}
            >
                {content}
            </motion.div>
        )
    }
}

/* Takes an SVG string and prefix all the ids and their occurrence with the given string */
export function prefixIdsInSVG(svg: string, prefix: string): string {
    const domParser = new DOMParser()
    try {
        const doc = domParser.parseFromString(svg, "image/svg+xml")
        const el = doc.getElementsByTagName("svg")[0]
        if (!el) return svg

        const sanitizedPrefix = sanitizeString(prefix)
        recursivelyPrefixId(el, sanitizedPrefix)
        return el.outerHTML
    } catch (error) {
        throw Error(`Failed to parse SVG: ${error}`)
    }
}

// Valid SVG IDs only include designated characters (letters, digits, and a few punctuation marks),
// and do not start with a digit, a full stop (.) character, or a hyphen-minus (-) character.
// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/id
function sanitizeString(str: string): string {
    return str.replace(/[^a-z0-9\-_:\.]|^[^a-z]+/gi, "") // source: https://stackoverflow.com/a/9635731/9300219
}

function recursivelyPrefixId(el: Element, prefix: string) {
    // element itself
    prefixId(el, prefix)

    // handle children
    const childNodes = Array.from(el.children)
    childNodes.forEach(node => {
        recursivelyPrefixId(node, prefix)
    })
}

function prefixId(el: Element, prefix: string) {
    const attributes = el.getAttributeNames()
    attributes.forEach(attr => {
        const value = el.getAttribute(attr)
        if (!value) return
        // prefix the id
        if (attr === "id") {
            el.setAttribute(attr, `${prefix}_${value}`)
        }

        // prefix occurrence in href (SVG2) or xlink:href
        if (attr === "href" || attr === "xlink:href") {
            const [base, fragmentIdentifier] = value.split("#")
            // The value might have a base URL in two cases:
            // 1. It's a hyperlink
            // 2. It's referencing the fragment from another document
            // In both cases we don't want to touch the value
            if (base) return

            el.setAttribute(attr, `#${prefix}_${fragmentIdentifier}`)
            return
        }

        // prefix occurrence in url()
        const URL_REF = "url(#"
        if (value.includes(URL_REF)) {
            const prefixedValue = value.replace(URL_REF, `${URL_REF}${prefix}_`)
            el.setAttribute(attr, prefixedValue)
        }
    })
}
