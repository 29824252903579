import { useLayoutEffect } from "react"
import { MotionFeature, FeatureProps, MotionValue } from "framer-motion"
import { complex } from "style-value-types"
import { AutoAnimationConfig } from "../../components/AnimateLayout/animation"

function getAnimatable(value: string | number) {
    if (typeof value === "string") {
        return value === "0" ? "0px" : complex.getAnimatableNone(value)
    }
    return 0
}

export const animatableStyles = [
    "rotate",
    "borderTopWidth",
    "borderRightWidth",
    "borderBottomWidth",
    "borderLeftWidth",
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
    "boxShadow",
]

function isNil(value: string | number | undefined) {
    return !value || value === "0"
}

/**
 * Automatically animate `style` from a previous `motion` component with a matching `layoutId`.
 * These values will have been snapshotted by the SharedLayoutTree component.
 */
function AnimateStyleFromPrevious({ visualElement, controls }: FeatureProps) {
    useLayoutEffect(() => {
        // On mount, check the layout animation config for snapshotted values and animate from them
        // if they're different to those on this component
        visualElement.onLayoutUpdate((_a, _b, { transition, current, target }: AutoAnimationConfig) => {
            // If we're missing a from/to, don't animate
            if (!current || !target) return

            const values = {}
            const allTransitions = {}

            animatableStyles.forEach(key => {
                let currentValue = current[key]
                let targetValue = target[key]
                if (targetValue instanceof MotionValue) targetValue = targetValue.get()

                if (isNil(currentValue) && isNil(targetValue as string | number)) return

                if (currentValue === undefined) {
                    currentValue = getAnimatable(targetValue as string | number)
                } else if (targetValue === undefined) {
                    targetValue = getAnimatable(currentValue)
                }

                if (currentValue === "0") currentValue = "0px"
                if (targetValue === "0") targetValue = "0px"

                values[key] = targetValue

                // If the values are unchanged between screens, we still want to ensure there's a visual update
                // in case this screen has been cached and left in a post-animation state
                const thisTransition = currentValue !== targetValue ? transition : { type: false }

                allTransitions[key] = {
                    ...thisTransition,
                    from: currentValue,
                }
            })
            if (Object.keys(values).length) {
                controls.start({ ...values, transition: allTransitions })
            }
        })
    }, [])

    return null
}

export const animateStyle: MotionFeature = {
    key: "animate-style",
    shouldRender: ({ layout, layoutId }) => !!layout || layoutId !== undefined,
    getComponent: () => AnimateStyleFromPrevious,
}
