import { addScaleCorrection, AxisBox2D, BoxDelta, Point2D } from "framer-motion"

const correctBorderScale = (axis: "x" | "y") => ({
    process: (latest: number, _viewportBox: AxisBox2D, delta: BoxDelta, treeScale: Point2D) => {
        if (typeof latest === "string") latest = parseFloat(latest)
        if (latest === 0) return "0px"

        let corrected = Math.round(latest / delta[axis].scale / treeScale[axis])
        corrected = Math.max(corrected, 1)
        return corrected + "px"
    },
})

addScaleCorrection({
    borderTopWidth: correctBorderScale("y"),
    borderLeftWidth: correctBorderScale("x"),
    borderRightWidth: correctBorderScale("x"),
    borderBottomWidth: correctBorderScale("y"),
})
