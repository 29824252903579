import {
    ContentState,
    convertFromRaw,
    Editor,
    EditorProps,
    EditorState,
    RawDraftContentState,
    Modifier,
    SelectionState,
} from "draft-js"
import { safeWindow } from "../../utils/safeWindow"
import * as React from "react"
import { Animatable } from "../../animation/Animatable"
import { deviceFont } from "../../utils/environment"
import { fontStore } from "../fonts/fontStore"
import { draftStyleFunction } from "../style/draft"
import { collectTextShadowsForProps } from "../style/shadow"
import { FilterProperties } from "../traits/Filters"
import {
    calculateRect,
    NewConstraintProperties,
    ParentSize,
    ParentSizeState,
    useParentSize,
} from "../types/NewConstraints"
import { Rect } from "../types/Rect"
import { RenderTarget } from "../types/RenderEnvironment"
import { Shadow } from "../types/Shadow"
import { collectFiltersFromProps } from "../utils/filtersForNode"
import { injectComponentCSSRules } from "../utils/injectComponentCSSRules"
import { ComponentContainerContext } from "./ComponentContainer"
import { Layer, LayerProps } from "./Layer"
import { draftBlockRendererFunction } from "./TextBlock"
import { forceLayerBackingWithCSSProperties } from "../utils/setLayerBacked"
import { isFiniteNumber } from "../utils/isFiniteNumber"
import { useLayoutId } from "../utils/useLayoutId"
import { motion } from "framer-motion"
import { transformTemplate } from "../utils/transformTemplate"
import { useResourceLoading } from "./ResourceLoading"

/**
 * @internal
 */
export type TextAlignment = "left" | "right" | "center" | undefined

/**
 * @internal
 */
export type TextVerticalAlignment = "top" | "center" | "bottom"

/**
 * @internal
 */
export interface TextProps extends NewConstraintProperties, Partial<FilterProperties> {
    rotation: Animatable<number> | number
    visible: boolean
    name?: string
    contentState?: any /* ContentState, but api-extractor fails because of it: https://github.com/Microsoft/web-build-tools/issues/949 */
    alignment: TextAlignment
    verticalAlignment: TextVerticalAlignment
    autoSize: boolean
    opacity?: number
    shadows: Shadow[]
    style?: React.CSSProperties
    text?: string
    font?: string
    parentSize?: ParentSize
}

/**
 * @internal
 */
export interface TextProperties extends TextProps, LayerProps {
    rawHTML?: string
    isEditable?: boolean
    fonts?: string[]
    layoutId?: string | undefined
    /** @internal for testing */
    environment?(): RenderTarget
}

// Before migrating to functional components we need to get parentSize data from context
/**
 * @internal
 */
export function Text(props: Partial<TextProperties>) {
    const parentSize = useParentSize()
    const layoutId = useLayoutId(props)

    useResourceLoading(async () => {
        if (props.fonts === undefined) return
        await fontStore.loadWebFontsFromSelectors(props.fonts)
    })

    return <TextComponent {...props} layoutId={layoutId} parentSize={parentSize} />
}

class TextComponent extends Layer<TextProperties, {}> {
    static supportsConstraints = true
    static defaultTextProps: TextProps = {
        opacity: undefined,
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
        _constraints: {
            enabled: true,
            aspectRatio: null,
        },
        rotation: 0,
        visible: true,
        contentState: undefined,
        alignment: undefined,
        verticalAlignment: "top",
        autoSize: true,
        shadows: [],
        font: "16px " + deviceFont(),
    }

    static readonly defaultProps: TextProperties = {
        ...Layer.defaultProps,
        ...TextComponent.defaultTextProps,
        isEditable: false,
        environment: RenderTarget.current,
    }

    editorText: string | undefined
    private editorState: EditorState | null

    get frame(): Rect | null {
        return calculateRect(this.props, this.props.parentSize || ParentSizeState.Unknown, false)
    }

    private editorStateForContentState(contentState?: ContentState | RawDraftContentState) {
        if (contentState) {
            if (!(contentState instanceof ContentState)) {
                contentState = convertFromRaw(contentState)
            }
            return EditorState.createWithContent(contentState)
        } else {
            return EditorState.createEmpty()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: TextProperties) {
        if (nextProps.contentState !== this.props.contentState) {
            this.editorState = this.editorStateForContentState(nextProps.contentState)
        }
    }

    render() {
        // Refactor to use React.useContext()
        return <ComponentContainerContext.Consumer>{this.renderMain}</ComponentContainerContext.Consumer>
    }

    /** Used by the ComponentContainerContext */
    private renderMain = (isCodeComponentChild: boolean) => {
        if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()

        const {
            font,
            fonts: fontSelectors,
            visible,
            rotation,
            alignment,
            autoSize,
            willChangeTransform,
            opacity,
            id,
            _forwardedOverrides,
            layoutId,
        } = this.props
        const frame = this.frame

        if (!visible) {
            return null
        }
        if (fontSelectors) {
            fontStore.loadWebFontsFromSelectors(fontSelectors)
        }

        injectComponentCSSRules()

        // We want to hide the Text component underneath the TextEditor when editing.
        const isHidden = this.props.isEditable && this.props.environment!() === RenderTarget.canvas

        const style: React.CSSProperties = {
            wordWrap: "break-word",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: convertVerticalAlignment(this.props.verticalAlignment),
            opacity: isHidden ? 0 : opacity,
            flexShrink: 0,
        }

        const dataProps = {
            "data-framer-component-type": "Text",
        }

        if (autoSize) {
            dataProps["data-framer-component-text-autosized"] = "true"
        }

        const rotate = Animatable.getNumber(rotation)
        if (frame && RenderTarget.hasRestrictions()) {
            Object.assign(style, {
                transform: `translate(${frame.x}px, ${frame.y}px) rotate(${rotate.toFixed(4)}deg)`,
                // Using “auto” fixes wrapping problems where our size calculation does not work out well when zooming the
                // text (due to rendering differences).
                width: autoSize ? "auto" : `${frame.width}px`,
                minWidth: `${frame.width}px`,
                height: `${frame.height}px`,
            })
        } else {
            const { left, right, top, bottom, width: externalWidth, height: externalHeight } = this.props

            let width: number | string | undefined
            let height: number | string | undefined
            if (autoSize) {
                width = "auto"
                height = "auto"
            } else {
                if (!isFiniteNumber(left) || !isFiniteNumber(right)) {
                    width = externalWidth
                }
                if (!isFiniteNumber(top) || !isFiniteNumber(bottom)) {
                    height = externalHeight
                }
            }

            Object.assign(style, {
                left,
                right,
                top,
                bottom,
                width,
                height,
                rotate,
            })
        }

        collectFiltersFromProps(this.props, style)
        collectTextShadowsForProps(this.props, style)

        if (style.opacity === 1 || style.opacity === undefined) {
            // Wipe opacity setting if it's the default (1 or undefined)
            delete style.opacity
        }

        if (willChangeTransform) {
            // We're not using Layer.applyWillChange here, because adding willChange:transform causes clipping issues in export
            forceLayerBackingWithCSSProperties(style)
        }

        let rawHTML = this.props.rawHTML
        let contentState = this.props.contentState

        let text: string | undefined = undefined
        if (id && _forwardedOverrides) {
            const value = _forwardedOverrides[id]
            if (typeof value === "string") {
                text = _forwardedOverrides[id]
            }
        } else {
            text = this.props.text
        }

        if (text !== undefined) {
            if (rawHTML) {
                rawHTML = replaceHTMLWithText(rawHTML, text)
            } else if (contentState) {
                contentState = replaceContentStateWithText(contentState, text)
            } else {
                rawHTML = `<p style="font: ${font}">${text}</p>`
            }
        }

        if (this.props.style) {
            Object.assign(style, this.props.style)
        }

        const hasTransformTemplate = !frame || !RenderTarget.hasRestrictions()

        if (rawHTML) {
            style.textAlign = alignment
            style.whiteSpace = "pre-wrap"
            style.wordWrap = "break-word"
            style.lineHeight = "1px"

            return (
                <motion.div
                    layoutId={layoutId}
                    {...dataProps}
                    style={style}
                    transformTemplate={hasTransformTemplate ? transformTemplate(this.props.center) : undefined}
                    dangerouslySetInnerHTML={{ __html: rawHTML! }}
                    data-center={this.props.center}
                />
            )
        }

        if (!this.editorState || this.editorText !== text) {
            this.editorText = text
            this.editorState = this.editorStateForContentState(contentState)
        }

        return (
            <motion.div
                layoutId={layoutId}
                {...dataProps}
                transformTemplate={hasTransformTemplate ? transformTemplate(this.props.center) : undefined}
                data-center={this.props.center}
                style={style}
            >
                <Editor
                    editorState={this.editorState}
                    onChange={this.onChange}
                    readOnly={true}
                    customStyleFn={draftStyleFunction}
                    blockRendererFn={this.blockRendererFn}
                    textAlignment={alignment}
                />
            </motion.div>
        )
    }

    private blockRendererFn: EditorProps["blockRendererFn"] = block => {
        return draftBlockRendererFunction({ editable: false, alignment: this.props.alignment })(block)
    }

    private onChange = (_: EditorState) => {
        // NOOP
    }
}

function replaceHTMLWithText(rawHTML: string, text: string): string {
    const orig = rawHTML.split('<span data-text="true">')
    return orig[0] + '<span data-text="true">' + text + "</span></span>"
}

function replaceContentStateWithText(contentState: ContentState | RawDraftContentState, text: string): ContentState {
    // The presentation tree render path passes down RawDraftContentState and
    // the canvas render path provides ContentState so we need to handle both.
    if (!(contentState instanceof ContentState)) {
        contentState = convertFromRaw(contentState)
    }

    const updatedContentState = ContentState.createFromText(text)
    const firstBlock = contentState.getFirstBlock()
    if (!firstBlock || firstBlock.getLength() === 0) {
        return updatedContentState
    }

    const initialStyles = firstBlock.getInlineStyleAt(0)
    const selectionState = new SelectionState({
        anchorKey: updatedContentState.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: updatedContentState.getLastBlock().getKey(),
        focusOffset: updatedContentState.getLastBlock().getLength(),
    })

    return initialStyles.reduce(
        (nextContentState: ContentState, inlineStyle: string) =>
            Modifier.applyInlineStyle(nextContentState, selectionState, inlineStyle),
        updatedContentState
    )
}

function convertVerticalAlignment(verticalAlignment: TextVerticalAlignment): "center" | "flex-start" | "flex-end" {
    switch (verticalAlignment) {
        case "top":
            return "flex-start"
        case "center":
            return "center"
        case "bottom":
            return "flex-end"
    }
}
