type AssetReference = string

// This includes the comma that seperates the mediatype from the data
const mediatype = "framer/asset-reference,"

export function isAssetReference(value: string): boolean {
    return value.startsWith(`data:${mediatype}`)
}

export function createAssetReference(
    identifier: string,
    packageIdentifier: string | null = null,
    originalFilename: string | null = null
): AssetReference {
    if (identifier === "") {
        return ""
    }
    const reference = new URL(`data:${mediatype}${identifier}`)
    if (originalFilename) {
        reference.searchParams.set("originalFilename", originalFilename)
    }
    if (packageIdentifier) {
        reference.searchParams.set("packageIdentifier", packageIdentifier)
    }
    return reference.href
}

export function parseAssetReference(reference: AssetReference) {
    if (!isAssetReference(reference)) {
        return
    }
    try {
        const url = new URL(reference)
        const identifier = url.pathname.substring(mediatype.length)
        const originalFilename = url.searchParams.get("originalFilename")
        const packageIdentifier = url.searchParams.get("packageIdentifier")
        return {
            identifier,
            originalFilename,
            packageIdentifier,
        }
    } catch {
        return
    }
}
