import { safeWindow } from "../../utils/safeWindow"
import { Map, OrderedMap } from "immutable"

// These types don’t do anything except make the code more readable
type Family = string
type Style = string
type Selector = string
type Weight = number | undefined
type Font = { selector: Selector; weight: Weight }

type TypefaceMembers = OrderedMap<Style, Font>
type Typefaces = OrderedMap<Family, TypefaceMembers>
type FontSelectorInfo = [Family, Style, Weight]
// Parentheses for (slightly) improved readability only
type TypefaceDescriptors = [Family, [Style, Selector, Weight][]][]

function convertToOrderedMap(descriptors: TypefaceDescriptors): Typefaces {
    return OrderedMap<Family, TypefaceMembers>(
        descriptors.map(descriptor => [
            descriptor[0],
            OrderedMap<Style, Selector>(
                descriptor[1].map(member => [
                    member[0],
                    {
                        selector: member[1],
                        weight: member[2],
                    },
                ])
            ),
        ])
    )
}

const safeFonts: TypefaceDescriptors = [
    [
        "Arial",
        [
            ["Regular", "Arial", undefined],
            ["Black", "Arial-Black", undefined],
            ["Narrow", "Arial Narrow", undefined],
            ["Rounded Bold", "Arial Rounded MT Bold", undefined],
        ],
    ],
    [
        "Avenir",
        [
            ["Book", "Avenir", undefined],
            ["Light", "Avenir-Light", undefined],
            ["Medium", "Avenir-Medium", undefined],
            ["Heavy", "Avenir-Heavy", undefined],
            ["Black", "Avenir-Black", undefined],
        ],
    ],
    [
        "Avenir Next",
        [
            ["Regular", "Avenir Next", undefined],
            ["Ultra Light", "AvenirNext-UltraLight", undefined],
            ["Medium", "AvenirNext-Medium", undefined],
            ["Demi Bold", "AvenirNext-DemiBold", undefined],
            ["Heavy", "AvenirNext-Heavy", undefined],
        ],
    ],
    [
        "Avenir Next Condensed",
        [
            ["Regular", "Avenir Next Condensed", undefined],
            ["Ultra Light", "AvenirNextCondensed-UltraLight", undefined],
            ["Medium", "AvenirNextCondensed-Medium", undefined],
            ["Demi Bold", "AvenirNextCondensed-DemiBold", undefined],
            ["Heavy", "AvenirNextCondensed-Heavy", undefined],
        ],
    ],
    [
        "Baskerville",
        [
            ["Regular", "Baskerville", undefined],
            ["Semi Bold", "Baskerville-SemiBold", undefined],
        ],
    ],
    [
        "Bodoni 72",
        [
            ["Book", "Bodoni 72", undefined],
            ["Oldstyle", "Bodoni 72 Oldstyle", undefined],
            ["Smallcaps", "Bodoni 72 Smallcaps", undefined],
        ],
    ],
    ["Courier", [["Regular", "Courier", undefined]]],
    ["Courier New", [["Regular", "Courier New", undefined]]],
    [
        "Futura",
        [
            ["Medium", "Futura", undefined],
            ["Condensed", "Futura-CondensedMedium", undefined],
            ["Condensed ExtraBold", "Futura-CondensedExtraBold", undefined],
        ],
    ],
    ["Georgia", [["Regular", "Georgia", undefined]]],
    [
        "Gill Sans",
        [
            ["Regular", "Gill Sans", undefined],
            ["Light", "GillSans-Light", undefined],
            ["SemiBold", "GillSans-SemiBold", undefined],
            ["UltraBold", "GillSans-UltraBold", undefined],
        ],
    ],
    [
        "Helvetica",
        [
            ["Regular", "Helvetica", undefined],
            ["Light", "Helvetica-Light", undefined],
        ],
    ],
    [
        "Helvetica Neue",
        [
            ["Regular", "Helvetica Neue", undefined],
            ["UltraLight", "HelveticaNeue-UltraLight", undefined],
            ["Thin", "HelveticaNeue-Thin", undefined],
            ["Light", "HelveticaNeue-Light", undefined],
            ["Medium", "HelveticaNeue-Medium", undefined],
            ["Condensed Bold", "HelveticaNeue-CondensedBold", undefined],
            ["Condensed Black", "HelveticaNeue-CondensedBlack", undefined],
        ],
    ],
    ["Hoefler Text", [["Regular", "Hoefler Text", undefined]]],
    ["Impact", [["Regular", "Impact", undefined]]],
    ["Lucida Grande", [["Regular", "Lucida Grande", undefined]]],
    ["Menlo", [["Regular", "Menlo", undefined]]],
    ["Monaco", [["Regular", "Monaco", undefined]]],
    [
        "Optima",
        [
            ["Regular", "Optima", undefined],
            ["ExtraBlack", "Optima-ExtraBlack", undefined],
        ],
    ],
    ["Palatino", [["Regular", "Palatino", undefined]]],
    [
        "SF Compact Display",
        [
            ["Regular", "__SF-Compact-Display-Regular__", 400],
            ["Ultralight", "__SF-Compact-Display-Ultralight__", 100],
            ["Thin", "__SF-Compact-Display-Thin__", 200],
            ["Light", "__SF-Compact-Display-Light__", 300],
            ["Medium", "__SF-Compact-Display-Medium__", 500],
            ["Semibold", "__SF-Compact-Display-Semibold__", 600],
            ["Bold", "__SF-Compact-Display-Bold__", 700],
            ["Heavy", "__SF-Compact-Display-Heavy__", 800],
            ["Black", "__SF-Compact-Display-Black__", 900],
        ],
    ],
    [
        "SF Compact Rounded",
        [
            ["Regular", "__SF-Compact-Rounded-Regular__", 400],
            ["Ultralight", "__SF-Compact-Rounded-Ultralight__", 100],
            ["Thin", "__SF-Compact-Rounded-Thin__", 200],
            ["Light", "__SF-Compact-Rounded-Light__", 300],
            ["Medium", "__SF-Compact-Rounded-Medium__", 500],
            ["Semibold", "__SF-Compact-Rounded-Semibold__", 600],
            ["Bold", "__SF-Compact-Rounded-Bold__", 700],
            ["Heavy", "__SF-Compact-Rounded-Heavy__", 800],
            ["Black", "__SF-Compact-Rounded-Black__", 900],
        ],
    ],
    [
        "SF Compact Text",
        [
            ["Regular", "__SF-Compact-Text-Regular__", 400],
            ["Light", "__SF-Compact-Text-Light__", 200],
            ["Medium", "__SF-Compact-Text-Medium__", 500],
            ["Semibold", "__SF-Compact-Text-Semibold__", 600],
            ["Bold", "__SF-Compact-Text-Bold__", 700],
            ["Heavy", "__SF-Compact-Text-Heavy__", 800],
            ["Italic", "__SF-Compact-Text-Italic__", 400],
            ["Light Italic", "__SF-Compact-Text-Light-Italic__", 200],
            ["Medium Italic", "__SF-Compact-Text-Medium-Italic__", 500],
            ["Bold Italic", "__SF-Compact-Text-Bold-Italic__", 700],
            ["Heavy Italic", "__SF-Compact-Text-Heavy-Italic__", 800],
            ["Semibold Italic", "__SF-Compact-Text-Semibold-Italic__", 600],
        ],
    ],
    [
        "SF Pro Display",
        [
            ["Regular", "__SF-UI-Display-Regular__", 400],
            ["Ultralight", "__SF-UI-Display-Ultralight__", 100],
            ["Thin", "__SF-UI-Display-Thin__", 200],
            ["Light", "__SF-UI-Display-Light__", 300],
            ["Medium", "__SF-UI-Display-Medium__", 500],
            ["Semibold", "__SF-UI-Display-Semibold__", 600],
            ["Bold", "__SF-UI-Display-Bold__", 700],
            ["Heavy", "__SF-UI-Display-Heavy__", 800],
            ["Black", "__SF-UI-Display-Black__", 900],
            ["Italic", "__SF-UI-Display-Italic__", 400],
            ["Ultralight Italic", "__SF-UI-Display-Ultralight-Italic__", 100],
            ["Thin Italic", "__SF-UI-Display-Thin-Italic__", 200],
            ["Light Italic", "__SF-UI-Display-Light-Italic__", 300],
            ["Medium Italic", "__SF-UI-Display-Medium-Italic__", 500],
            ["Semibold Italic", "__SF-UI-Display-Semibold-Italic__", 600],
            ["Bold Italic", "__SF-UI-Display-Bold-Italic__", 700],
            ["Heavy Italic", "__SF-UI-Display-Heavy-Italic__", 800],
            ["Black Italic", "__SF-UI-Display-Black-Italic__", 900],
        ],
    ],
    [
        "SF Pro Display Condensed",
        [
            ["Regular", "__SF-UI-Display-Condensed-Regular__", 400],
            ["Ultralight", "__SF-UI-Display-Condensed-Ultralight__", 100],
            ["Thin", "__SF-UI-Display-Condensed-Thin__", 200],
            ["Light", "__SF-UI-Display-Condensed-Light__", 300],
            ["Medium", "__SF-UI-Display-Condensed-Medium__", 500],
            ["Semibold", "__SF-UI-Display-Condensed-Semibold__", 600],
            ["Bold", "__SF-UI-Display-Condensed-Bold__", 700],
            ["Heavy", "__SF-UI-Display-Condensed-Heavy__", 800],
            ["Black", "__SF-UI-Display-Condensed-Black__", 900],
        ],
    ],
    [
        "SF Pro Text",
        [
            ["Regular", "__SF-UI-Text-Regular__", 400],
            ["Light", "__SF-UI-Text-Light__", 200],
            ["Medium", "__SF-UI-Text-Medium__", 500],
            ["Semibold", "__SF-UI-Text-Semibold__", 600],
            ["Bold", "__SF-UI-Text-Bold__", 700],
            ["Heavy", "__SF-UI-Text-Heavy__", 800],
            ["Italic", "__SF-UI-Text-Italic__", 400],
            ["Light Italic", "__SF-UI-Text-Light-Italic__", 200],
            ["Medium Italic", "__SF-UI-Text-Medium-Italic__", 500],
            ["Semibold Italic", "__SF-UI-Text-Semibold-Italic__", 600],
            ["Bold Italic", "__SF-UI-Text-Bold-Italic__", 700],
            ["Heavy Italic", "__SF-UI-Text-Heavy-Italic__", 800],
        ],
    ],
    [
        "SF Pro Text Condensed",
        [
            ["Regular", "__SF-UI-Text-Condensed-Regular__", 400],
            ["Light", "__SF-UI-Text-Condensed-Light__", 200],
            ["Medium", "__SF-UI-Text-Condensed-Medium__", 500],
            ["Semibold", "__SF-UI-Text-Condensed-Semibold__", 600],
            ["Bold", "__SF-UI-Text-Condensed-Bold__", 700],
            ["Heavy", "__SF-UI-Text-Condensed-Heavy__", 800],
        ],
    ],
    ["Tahoma", [["Regular", "Tahoma", undefined]]],
    ["Times", [["Regular", "Times", undefined]]],
    ["Times New Roman", [["Regular", "Times New Roman", undefined]]],
    ["Trebuchet", [["Regular", "Trebuchet MS", undefined]]],
    ["Verdana", [["Regular", "Verdana", undefined]]],
]

export const typefaceAliases: { [key: string]: string } = {
    "__SF-Compact-Display-Regular__": "SFCompactDisplay-Regular|.SFCompactDisplay-Regular",
    "__SF-Compact-Display-Ultralight__": "SFCompactDisplay-Ultralight|.SFCompactDisplay-Ultralight",
    "__SF-Compact-Display-Thin__": "SFCompactDisplay-Thin|.SFCompactDisplay-Thin",
    "__SF-Compact-Display-Light__": "SFCompactDisplay-Light|.SFCompactDisplay-Light",
    "__SF-Compact-Display-Medium__": "SFCompactDisplay-Medium|.SFCompactDisplay-Medium",
    "__SF-Compact-Display-Semibold__": "SFCompactDisplay-Semibold|.SFCompactDisplay-Semibold",
    "__SF-Compact-Display-Heavy__": "SFCompactDisplay-Heavy|.SFCompactDisplay-Heavy",
    "__SF-Compact-Display-Black__": "SFCompactDisplay-Black|.SFCompactDisplay-Black",
    "__SF-Compact-Display-Bold__": "SFCompactDisplay-Bold|.SFCompactDisplay-Bold",

    "__SF-UI-Text-Regular__": ".SFNSText|SFProText-Regular|SFUIText-Regular|.SFUIText",
    "__SF-UI-Text-Light__": ".SFNSText-Light|SFProText-Light|SFUIText-Light|.SFUIText-Light",
    "__SF-UI-Text-Medium__": ".SFNSText-Medium|SFProText-Medium|SFUIText-Medium|.SFUIText-Medium",
    "__SF-UI-Text-Semibold__": ".SFNSText-Semibold|SFProText-Semibold|SFUIText-Semibold|.SFUIText-Semibold",
    "__SF-UI-Text-Bold__": ".SFNSText-Bold|SFProText-Bold|SFUIText-Bold|.SFUIText-Bold",
    "__SF-UI-Text-Heavy__": ".SFNSText-Heavy|SFProText-Heavy|.SFUIText-Heavy",
    "__SF-UI-Text-Italic__": ".SFNSText-Italic|SFProText-Italic|SFUIText-Italic|.SFUIText-Italic",
    "__SF-UI-Text-Light-Italic__":
        ".SFNSText-LightItalic|SFProText-LightItalic|SFUIText-LightItalic|.SFUIText-LightItalic",
    "__SF-UI-Text-Medium-Italic__":
        ".SFNSText-MediumItalic|SFProText-MediumItalic|SFUIText-MediumItalic|.SFUIText-MediumItalic",
    "__SF-UI-Text-Semibold-Italic__":
        ".SFNSText-SemiboldItalic|SFProText-SemiboldItalic|SFUIText-SemiboldItalic|.SFUIText-SemiboldItalic",
    "__SF-UI-Text-Bold-Italic__": ".SFNSText-BoldItalic|SFProText-BoldItalic|SFUIText-BoldItalic|.SFUIText-BoldItalic",
    "__SF-UI-Text-Heavy-Italic__": ".SFNSText-HeavyItalic|SFProText-HeavyItalic|.SFUIText-HeavyItalic",

    "__SF-Compact-Text-Regular__": "SFCompactText-Regular|.SFCompactText-Regular",
    "__SF-Compact-Text-Light__": "SFCompactText-Light|.SFCompactText-Light",
    "__SF-Compact-Text-Medium__": "SFCompactText-Medium|.SFCompactText-Medium",
    "__SF-Compact-Text-Semibold__": "SFCompactText-Semibold|.SFCompactText-Semibold",
    "__SF-Compact-Text-Bold__": "SFCompactText-Bold|.SFCompactText-Bold",
    "__SF-Compact-Text-Heavy__": "SFCompactText-Heavy|.SFCompactText-Heavy",
    "__SF-Compact-Text-Italic__": "SFCompactText-Italic|.SFCompactText-Italic",
    "__SF-Compact-Text-Light-Italic__": "SFCompactText-LightItalic|.SFCompactText-LightItalic",
    "__SF-Compact-Text-Medium-Italic__": "SFCompactText-MediumItalic|.SFCompactText-MediumItalic",
    "__SF-Compact-Text-Semibold-Italic__": "SFCompactText-SemiboldItalic|.SFCompactText-SemiboldItalic",
    "__SF-Compact-Text-Bold-Italic__": "SFCompactText-BoldItalic|.SFCompactText-BoldItalic",
    "__SF-Compact-Text-Heavy-Italic__": "SFCompactText-HeavyItalic|.SFCompactText-HeavyItalic",

    "__SF-UI-Display-Condensed-Regular__":
        ".SFNSDisplayCondensed-Regular|SFUIDisplayCondensed-Regular|.SFUIDisplayCondensed-Regular",
    "__SF-UI-Display-Condensed-Ultralight__":
        ".SFNSDisplayCondensed-Ultralight|SFUIDisplayCondensed-Ultralight|.SFUIDisplayCondensed-Ultralight",
    "__SF-UI-Display-Condensed-Thin__":
        ".SFNSDisplayCondensed-Thin|SFUIDisplayCondensed-Thin|.SFUIDisplayCondensed-Thin",
    "__SF-UI-Display-Condensed-Light__":
        ".SFNSDisplayCondensed-Light|SFUIDisplayCondensed-Light|.SFUIDisplayCondensed-Light",
    "__SF-UI-Display-Condensed-Medium__":
        ".SFNSDisplayCondensed-Medium|SFUIDisplayCondensed-Medium|.SFUIDisplayCondensed-Medium",
    "__SF-UI-Display-Condensed-Semibold__":
        ".SFNSDisplayCondensed-Semibold|SFUIDisplayCondensed-Semibold|.SFUIDisplayCondensed-Semibold",
    "__SF-UI-Display-Condensed-Bold__":
        ".SFNSDisplayCondensed-Bold|SFUIDisplayCondensed-Bold|.SFUIDisplayCondensed-Bold",
    "__SF-UI-Display-Condensed-Heavy__":
        ".SFNSDisplayCondensed-Heavy|SFUIDisplayCondensed-Heavy|.SFUIDisplayCondensed-Heavy",
    "__SF-UI-Display-Condensed-Black__": ".SFNSDisplayCondensed-Black|.SFUIDisplayCondensed-Black",

    "__SF-UI-Display-Regular__": ".SFNSDisplay|SFProDisplay-Regular|SFUIDisplay-Regular|.SFUIDisplay",
    "__SF-UI-Display-Ultralight__":
        ".SFNSDisplay-Ultralight|SFProDisplay-Ultralight|SFUIDisplay-Ultralight|.SFUIDisplay-Ultralight",
    "__SF-UI-Display-Thin__": ".SFNSDisplay-Thin|SFProDisplay-Thin|SFUIDisplay-Thin|.SFUIDisplay-Thin",
    "__SF-UI-Display-Light__": ".SFNSDisplay-Light|SFProDisplay-Light|SFUIDisplay-Light|.SFUIDisplay-Light",
    "__SF-UI-Display-Medium__": ".SFNSDisplay-Medium|SFProDisplay-Medium|SFUIDisplay-Medium|.SFUIDisplay-Medium",
    "__SF-UI-Display-Semibold__":
        ".SFNSDisplay-Semibold|SFProDisplay-Semibold|SFUIDisplay-Semibold|.SFUIDisplay-Semibold",
    "__SF-UI-Display-Bold__": ".SFNSDisplay-Bold|SFProDisplay-Bold|SFUIDisplay-Bold|.SFUIDisplay-Bold",
    "__SF-UI-Display-Heavy__": ".SFNSDisplay-Heavy|SFProDisplay-Heavy|SFUIDisplay-Heavy|.SFUIDisplay-Heavy",
    "__SF-UI-Display-Black__": ".SFNSDisplay-Black|SFProDisplay-Black|.SFUIDisplay-Black",
    "__SF-UI-Display-Italic__": ".SFNSDisplay-Italic|SFProDisplay-Italic|SFUIDisplay-Italic",
    "__SF-UI-Display-Ultralight-Italic__":
        ".SFNSDisplay-UltralightItalic|SFProDisplay-UltralightItalic|SFUIDisplay-UltralightItalic|.SFUIDisplay-UltralightItalic",
    "__SF-UI-Display-Thin-Italic__":
        ".SFNSDisplay-ThinItalic|SFProDisplay-ThinItalic|SFUIDisplay-ThinItalic|.SFUIDisplay-ThinItalic",
    "__SF-UI-Display-Light-Italic__":
        ".SFNSDisplay-LightItalic|SFProDisplay-LightItalic|SFUIDisplay-LightItalic|.SFUIDisplay-LightItalic",
    "__SF-UI-Display-Medium-Italic__":
        ".SFNSDisplay-MediumItalic|SFProDisplay-MediumItalic|SFUIDisplay-MediumItalic|.SFUIDisplay-MediumItalic",
    "__SF-UI-Display-Semibold-Italic__":
        ".SFNSDisplay-SemiboldItalic|SFProDisplay-SemiboldItalic|SFUIDisplay-SemiboldItalic|.SFUIDisplay-SemiboldItalic",
    "__SF-UI-Display-Bold-Italic__":
        ".SFNSDisplay-BoldItalic|SFProDisplay-BoldItalic|SFUIDisplay-BoldItalic|.SFUIDisplay-BoldItalic",
    "__SF-UI-Display-Heavy-Italic__":
        ".SFNSDisplay-HeavyItalic|SFProDisplay-HeavyItalic|SFUIDisplay-HeavyItalic|.SFUIDisplay-HeavyItalic",
    "__SF-UI-Display-Black-Italic__": ".SFNSDisplay-BlackItalic|SFProDisplay-BlackItalic|.SFUIDisplay-BlackItalic",

    "__SF-UI-Text-Condensed-Regular__":
        ".SFNSTextCondensed-Regular|SFUITextCondensed-Regular|.SFUITextCondensed-Regular",
    "__SF-UI-Text-Condensed-Light__": ".SFNSTextCondensed-Light|SFUITextCondensed-Light|.SFUITextCondensed-Light",
    "__SF-UI-Text-Condensed-Medium__": ".SFNSTextCondensed-Medium|SFUITextCondensed-Medium|.SFUITextCondensed-Medium",
    "__SF-UI-Text-Condensed-Semibold__":
        ".SFNSTextCondensed-Semibold|SFUITextCondensed-Semibold|.SFUITextCondensed-Semibold",
    "__SF-UI-Text-Condensed-Bold__": ".SFNSTextCondensed-Bold|SFUITextCondensed-Bold|.SFUITextCondensed-Bold",
    "__SF-UI-Text-Condensed-Heavy__": ".SFNSTextCondensed-Heavy|.SFUITextCondensed-Heavy",

    "__SF-Compact-Rounded-Regular__": "SFCompactRounded-Regular|.SFCompactRounded-Regular",
    "__SF-Compact-Rounded-Ultralight__": "SFCompactRounded-Ultralight|.SFCompactRounded-Ultralight",
    "__SF-Compact-Rounded-Thin__": "SFCompactRounded-Thin|.SFCompactRounded-Thin",
    "__SF-Compact-Rounded-Light__": "SFCompactRounded-Light|.SFCompactRounded-Light",
    "__SF-Compact-Rounded-Medium__": "SFCompactRounded-Medium|.SFCompactRounded-Medium",
    "__SF-Compact-Rounded-Semibold__": "SFCompactRounded-Semibold|.SFCompactRounded-Semibold",
    "__SF-Compact-Rounded-Bold__": "SFCompactRounded-Bold|.SFCompactRounded-Bold",
    "__SF-Compact-Rounded-Heavy__": "SFCompactRounded-Heavy|.SFCompactRounded-Heavy",
    "__SF-Compact-Rounded-Black__": "SFCompactRounded-Black|.SFCompactRounded-Black",
}

export const typefaces: Typefaces = convertToOrderedMap(
    typeof safeWindow !== "undefined" && safeWindow["SystemTypefaceDescriptors"] !== undefined
        ? safeWindow["SystemTypefaceDescriptors"]
        : safeFonts
)

export const defaultFontSelector =
    typeof safeWindow !== "undefined" && safeWindow["SystemTypefaceDefaultSelector"] !== undefined
        ? safeWindow["SystemTypefaceDefaultSelector"]
        : "Inter"

export const fontSelectors = Map<Selector, FontSelectorInfo>(
    typefaces.flatMap((members: TypefaceMembers, family: string) => {
        return Map(
            members
                .map((font: Font, member: Selector) => {
                    return [font.selector, [family, member, font.weight]]
                })
                .toArray()
        )
    })
)
