export function transformTemplate(center?: boolean | "x" | "y") {
    return (_: any, generated: string) => {
        if (center === true) {
            return `translate(-50%, -50%) ${generated}`
        } else {
            if (center === "x") {
                return `translateX(-50%) ${generated}`
            } else if (center === "y") {
                return `translateY(-50%) ${generated}`
            }
        }
        return generated
    }
}
