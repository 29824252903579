import { SharedLayoutAnimationConfig, HTMLVisualElement, AxisBox2D, MotionValue } from "framer-motion"

/**
 * @internal
 */
export interface Config {
    lead?: HTMLVisualElement
    transition?: any
    prevViewportBox?: AxisBox2D
    current?: { [key: string]: string | number }
    shouldStackAnimate?: boolean
}

/**
 * @internal
 */
export interface AutoAnimationConfig extends SharedLayoutAnimationConfig {
    current?: { [key: string]: string | number }
    target?: { [key: string]: string | number | MotionValue }
}

/**
 * Because the lead is always the target, and the follow is always the origin,
 * createAnimation can be much simpler than createCrossfadeAnimation in framer-motion.
 *
 * @internal
 */
export function createAnimation(animation: Config, isLead: boolean): SharedLayoutAnimationConfig {
    const { shouldStackAnimate, transition, lead, current, prevViewportBox } = animation

    const config: AutoAnimationConfig = {
        shouldStackAnimate,
        transition,
        current,
        target: lead?.config ? (lead.config as any).style : undefined,
    }

    if (!shouldStackAnimate) return config

    if (isLead) {
        config.originBox = prevViewportBox
    } else {
        config.targetBox = lead?.box
    }

    return config
}
