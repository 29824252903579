import * as React from "react"

type ResourceLoadingContextProps = (load: () => Promise<void>) => void

const ResourceLoadingContext = React.createContext<ResourceLoadingContextProps>((() => {}))

/**
 * This hook indicates that a component is loading resources like images or fonts.
 * Pass in an async function that resolves when everything has (pre)loaded.
 * Currently, it's used to hide canvas elements while loading.
 *
 * @internal
 */
export function useResourceLoading(load: () => Promise<void>) {
    const shouldTrackLoading = React.useRef(true)
    const trackResourceLoading = React.useContext(ResourceLoadingContext)

    if (shouldTrackLoading.current) {
        shouldTrackLoading.current = false
        trackResourceLoading(load)
    }
}

/**
 * @internal
 */
export function ResourceLoading({
    onMount,
    trackResourceLoading,
    children,
}: {
    onMount(): void
    trackResourceLoading(load: () => Promise<void>): void
    children: React.ReactNode
}) {
    // We track all the resources that we need to await with the initial render. If there is no resource that is pending
    //  after the initial render we can show the node directly.
    React.useEffect(onMount, [])

    return (
        <ResourceLoadingContext.Provider value={trackResourceLoading}>
            {children}
        </ResourceLoadingContext.Provider>
    )
}
