import { FontSource, TypefaceSourceName, Typeface, Font, TypefaceSourceNames, ReadonlyTypeface } from "./types"
import { typefaces as systemTypefaces, typefaceAliases } from "./fonts"

/** @internal */
export const systemTypefaceName = "System Default"

/** @internal */
export class LocalFontSource implements FontSource {
    name: TypefaceSourceName = TypefaceSourceNames.Local
    typefaces: Typeface[] = []
    byFamily = new Map<string, Typeface>()

    private typefaceAliasBySelector = new Map<string, string>()
    private typefaceAliases = new Map<string, string>()

    getTypefaceByFamily(family: string): ReadonlyTypeface | null {
        return this.byFamily.get(family) ?? null
    }
    // TODO: these are duplicated across implementations of FontSource
    // When adding a third source, we should abstract them
    createTypeface(family: string): Typeface {
        const typeface = { family: family, fonts: [], source: this.name }
        this.addTypeface(typeface)
        return typeface
    }

    private addTypeface(typeface: Typeface) {
        this.typefaces.push(typeface)
        this.byFamily.set(typeface.family, typeface)
    }
    // end of duplication

    importFonts(): Font[] {
        const fonts: Font[] = []
        for (const family of systemTypefaces.keys() as IterableIterator<string>) {
            const members = systemTypefaces.get(family)
            if (!members) continue
            const typeface: Typeface = this.createTypeface(family)
            for (const variant of members.keys() as IterableIterator<string>) {
                const member = members.get(variant)
                if (!member) continue
                const { selector, weight } = member
                // font.style is never defined in local fonts, we always use a specific font family that already includes the style
                const font: Font = {
                    variant,
                    selector,
                    weight,
                    typeface,
                    status: "loaded",
                }
                typeface.fonts.push(font)
            }
            fonts.push(...typeface.fonts)
        }

        for (const [key, value] of Object.entries(typefaceAliases)) {
            this.addTypefaceAlias(key, value)
        }
        const { typeface: systemTypeface, aliases } = this.getSystemTypeface()
        this.addTypeface(systemTypeface)
        for (const [key, value] of aliases) {
            this.addTypefaceAlias(key, value)
        }
        fonts.push(...systemTypeface.fonts)

        const interTypeface = this.importInterTypeface()
        fonts.push(...interTypeface.fonts)
        return fonts
    }

    interTypefaceSelectors: Set<string> = new Set()
    private importInterTypeface() {
        const inter = [
            ["Regular", "Inter", undefined],
            ["Thin", "Inter-Thin", 100],
            ["Extra Light", "Inter-ExtraLight", 200],
            ["Light", "Inter-Light", 300],
            ["Medium", "Inter-Medium", 500],
            ["Semibold", "Inter-SemiBold", 600],
            ["Bold", "Inter-Bold", 700],
            ["Extra Bold", "Inter-ExtraBold", 800],
            ["Black", "Inter-Black", 900],
            ["Thin Italic", "Inter-ThinItalic", 100],
            ["Extra Light Italic", "Inter-ExtraLightItalic", 300],
            ["Light Italic", "Inter-LightItalic", 300],
            ["Italic", "Inter-Italic", undefined],
            ["Medium Italic", "Inter-MediumItalic", 500],
            ["Semibold Italic", "Inter-SemiBoldItalic", 600],
            ["Bold Italic", "Inter-BoldItalic", 700],
            ["Extra Bold Italic", "Inter-ExtraBoldItalic", 800],
            ["Black Italic", "Inter-BlackItalic", 900],
        ] as const

        const typeface: Typeface = this.createTypeface("Inter")
        for (const entry of inter) {
            const [variant, selector, weight] = entry
            const font: Font = {
                variant,
                selector,
                weight,
                typeface,
                style: /italic/i.test(selector) ? "italic" : undefined,
            }
            typeface.fonts.push(font)
        }
        typeface.fonts.forEach(t => this.interTypefaceSelectors.add(t.selector))
        return typeface
    }

    private addTypefaceAlias(key: string, value: string) {
        this.typefaceAliases.set(key, value)
        this.typefaceAliasBySelector.set(value, key)
    }

    private getSystemTypeface(): { typeface: Typeface; aliases: Map<string, string> } {
        const fontFamilies = this.workaroundChrome81and82(
            // System fonts - Taken from https://furbo.org/stuff/systemfonts-new.html - "All Platforms" section
            "system-ui|-apple-system|BlinkMacSystemFont|Segoe UI|Roboto|Oxygen|Ubuntu|Cantarell|Fira Sans|Droid Sans|Helvetica Neue|sans-serif"
        )

        const typeface: Typeface = { family: systemTypefaceName, fonts: [], source: this.name }
        const aliases = new Map<string, string>()

        const weights: Weight[] = [400, 100, 200, 300, 500, 600, 700, 800, 900]
        const styles: ("normal" | "italic")[] = ["normal", "italic"]
        for (const style of styles) {
            for (const weight of weights) {
                const variant = createVariantName(weight, style)
                const alias = `__SystemDefault-${weight}-${style}__`
                const font: Font = {
                    variant,
                    selector: alias,
                    style: style === "normal" ? undefined : style,
                    weight: weight === 400 ? undefined : weight,
                    typeface,
                    status: "loaded",
                }
                typeface.fonts.push(font)
                aliases.set(alias, fontFamilies)
            }
        }
        return { typeface, aliases }
    }

    getTypefaceAliasBySelector(selector: string): string | null {
        return this.typefaceAliasBySelector.get(selector) || null
    }
    getTypefaceSelectorByAlias(alias: string): string | null {
        return this.typefaceAliases.get(alias) || null
    }
    /** Typeface aliases are in the format of `__Alias-Name__` */
    isTypefaceAlias(value: string): boolean {
        if (value && value.match(/^__.*__$/)) return true
        return false
    }

    /**
     * Use 'Inter' web font as System Default fonts on Mac with Chrome v81 v82
     * https://github.com/framer/company/issues/17277
     * https://bugs.chromium.org/p/chromium/issues/detail?id=1057654
     */
    private workaroundChrome81and82(s: string): string {
        const userAgent = navigator.userAgent
        if (!userAgent.includes("Mac OS X 10_15")) return s
        if (!userAgent.includes("Chrome/81") && !userAgent.includes("Chrome/82")) return s
        return `Inter|${s}`
    }
}

const fontWeightNames: Record<Weight, string> = {
    "100": "Thin",
    "200": "Extra Light",
    "300": "Light",
    "400": "Normal",
    "500": "Medium",
    "600": "Semi Bold",
    "700": "Bold",
    "800": "Extra Bold",
    "900": "Black",
}

type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

function createVariantName(weight: Weight, style: "normal" | "italic") {
    const friendlyStyle = style === "normal" ? "Regular" : "Italic"
    if (weight === 400) {
        return friendlyStyle
    }
    if (style !== "normal") {
        return `${fontWeightNames[weight]} ${friendlyStyle}`
    }
    return `${fontWeightNames[weight]}`
}
