import * as React from "react"
import { FrameWithMotion } from "../render/presentation/Frame"
import { MotionValue, PanInfo } from "framer-motion"
import { PageContentDimension, PageAlignment } from "./Page"
import { toJustifyOrAlignment } from "./Stack"

interface PageContainerProps {
    effect: { [key: string]: MotionValue } | undefined
    children?: React.ReactNode
    dragEnabled: boolean
    direction: "horizontal" | "vertical"
    contentWidth: PageContentDimension | number
    contentHeight: PageContentDimension | number
    alignment?: PageAlignment
    gap: number
    isLastPage: boolean
    contentOffset: { x: MotionValue<number>; y: MotionValue<number> }
    maxScrollOffset: number
    directionLock: boolean | undefined
    layoutId: string | undefined
    onDragStart: (event: MouseEvent | TouchEvent, info: PanInfo) => void
    onDrag: (event: MouseEvent | TouchEvent, info: PanInfo) => void
    onDragEnd: (event: MouseEvent | TouchEvent, info: PanInfo) => void
}

export const pageContentWrapperType = "PageContentWrapper"

export function PageContainer({
    children,
    effect,
    dragEnabled,
    direction,
    contentHeight,
    contentWidth,
    alignment,
    gap,
    isLastPage,
    contentOffset,
    maxScrollOffset,
    directionLock,
    onDragStart,
    onDrag,
    onDragEnd,
    layoutId,
}: PageContainerProps) {
    const isHorizontalDirection = direction === "horizontal"
    const dragAxis = isHorizontalDirection ? "x" : "y"
    const hasHorizontalGap = isHorizontalDirection && !isLastPage && gap
    const hasVerticalGap = !isHorizontalDirection && !isLastPage && gap
    const hasAutoWidth = contentWidth !== "stretch" && isHorizontalDirection
    const hasAutoHeight = contentHeight !== "stretch" && !isHorizontalDirection
    const wrapperWidth = hasAutoWidth ? "auto" : "100%"
    const wrapperHeight = hasAutoHeight ? "auto" : "100%"
    const containerWidth = hasHorizontalGap && wrapperWidth === "100%" ? `calc(100% + ${gap}px)` : wrapperWidth
    const containerHeight = hasVerticalGap && wrapperHeight === "100%" ? `calc(100% + ${gap}px)` : wrapperHeight

    return (
        <FrameWithMotion
            position={"relative"}
            data-framer-component-type={"PageContainer"}
            width={containerWidth}
            height={containerHeight}
            layoutId={layoutId ? `${layoutId}-container` : undefined}
            backgroundColor={"transparent"}
            drag={dragEnabled ? dragAxis : false}
            dragDirectionLock={directionLock}
            _dragX={contentOffset.x}
            _dragY={contentOffset.y}
            dragConstraints={{ top: -maxScrollOffset, left: -maxScrollOffset, right: 0, bottom: 0 }}
            onDrag={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            preserve3d={true}
            style={{
                paddingRight: hasHorizontalGap ? gap : 0,
                paddingBottom: hasVerticalGap ? gap : 0,
            }}
        >
            <FrameWithMotion
                position={"relative"}
                data-framer-component-type={pageContentWrapperType}
                width={wrapperWidth}
                height={wrapperHeight}
                preserve3d={false}
                key={effect ? Object.keys(effect).join("") : ""}
                backgroundColor={"transparent"}
                layoutId={layoutId ? `${layoutId}-child` : undefined}
                style={{
                    ...effect,
                    display: "flex",
                    flexDirection: isHorizontalDirection ? "row" : "column",
                    alignItems: alignment && toJustifyOrAlignment(alignment),
                }}
            >
                {children}
            </FrameWithMotion>
        </FrameWithMotion>
    )
}
