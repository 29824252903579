import * as React from "react"
import { isEqual } from "../utils/isEqual"
import { RenderTarget } from "../types/RenderEnvironment"
import { ReactNode } from "react"
import { resetSetStyle } from "../utils/useWebkitFixes"
import { elementForComponent } from "../utils/elementForComponent"
import { MotionStyle } from "framer-motion"
import { forceLayerBackingWithMotionStyle, forceLayerBackingWithCSSProperties } from "../utils/setLayerBacked"

/** @public */
export interface IdentityProps {
    id?: string
    duplicatedFrom?: string[]
}

export interface WillChangeTransformProp {
    willChangeTransform?: boolean
}

/** @public */
export interface LayerProps extends IdentityProps, WillChangeTransformProp {
    children?: ReactNode
    key?: React.Key
    /** @internal */
    _forwardedOverrides?: { [key: string]: any }
    /** @internal */
    _index?: number
    /** @internal */
    _canMagicMotion?: boolean
}
/**
 * @public
 */
export class Layer<P extends Partial<LayerProps>, S> extends React.Component<P, S> {
    static readonly defaultProps: LayerProps = {}

    static applyWillChange(props: WillChangeTransformProp, style: MotionStyle, usingMotionStyle: boolean) {
        const shouldApply = props.willChangeTransform || RenderTarget.current() === RenderTarget.export

        if (shouldApply) {
            if (usingMotionStyle) {
                forceLayerBackingWithMotionStyle(style)
            } else {
                forceLayerBackingWithCSSProperties(style as React.CSSProperties)
            }
        }
    }

    /** @internal */
    shouldComponentUpdate(nextProps: P, nextState: S) {
        return this.state !== nextState || !isEqual(this.props, nextProps)
    }

    props: Readonly<P> & Readonly<{ children?: ReactNode }>

    /** @internal */
    componentDidUpdate(prevProps: P) {
        // Workarounds for WebKit bugs

        // Some styles have to be toggled to take effect in certain situations.
        // Not using type safety, uses lots of internal knowledge for efficiency
        // To use this as a hook, see useWebKitFixes

        const element = elementForComponent(this)

        if (this.props["clip"] && this.props["radius"] === 0 && prevProps["radius"] !== 0) {
            resetSetStyle(element, "overflow", "hidden", false)
        }
    }
}
