import * as React from "react"
import { MotionStyle } from "framer-motion"

import { StackSpecificProps, StackAlignment, StackDistribution, StackDirection } from "./types"
import { FrameWithMotion, FrameProps } from "../../render/presentation/Frame"
import { isFiniteNumber } from "../../render/utils/isFiniteNumber"
import { Layer } from "../../render/presentation/Layer"
import { ControlType } from "../../render/types/PropertyControls"
import { isReactChild, isReactElement } from "../../utils/type-guards"
import { paddingFromProps, makePaddingString } from "../utils/paddingFromProps"

import { useProvideParentSize, ParentSizeState, constraintsEnabled } from "../../render/types/NewConstraints"
import { unwrapFrameProps } from "../../render/presentation/Frame/FrameWithMotion"
import { WillChangeTransformProp } from "../../render/presentation/Layer"
import { processOverrideForwarding } from "../../render/utils/processOverrideForwarding"
import { injectComponentCSSRules } from "../../render/utils/injectComponentCSSRules"
import { addPropertyControls } from "../../utils/addPropertyControls"
import { useLayoutId } from "../../render/utils/useLayoutId"

/**
 * The Stack component will automatically distribute its contents based on its
 * properties. The Stack component takes the same props as the {@link Frame} component
 * as well as a few additional interface defined below.
 *
 * @remarks
 * ```jsx
 * function MyComponent() {
 *   return (
 *     <Stack>
 *       <Frame />
 *       <Frame />
 *       <Frame />
 *     </Stack>
 *   )
 * }
 * ```
 * @public
 */
export interface StackProperties extends StackSpecificProps, FrameProps, WillChangeTransformProp {
    children?: React.ReactNode
}

type FlexDirection = "column" | "row" | "column-reverse" | "row-reverse"

/**
 * @public
 */
// tslint:disable-next-line:no-shadowed-variable
export const Stack = React.memo(function Stack(stackProps: Partial<StackProperties>) {
    const {
        direction = "vertical",
        distribution = "start",
        alignment = "center",
        gap = 10,
        children,
        style: styleProp,
        willChangeTransform,
        __fromCodeComponentNode,
        ...containerProps
    } = stackProps

    injectComponentCSSRules()
    const flexDirection = toFlexDirection(direction)
    const isReverse = isReverseDirection(flexDirection)
    const justifyContent = toJustifyOrAlignment(distribution)
    const style: MotionStyle = {
        display: "flex",
        flexDirection,
        justifyContent: justifyContent,
        alignItems: toJustifyOrAlignment(alignment),
        padding: makePaddingString(paddingFromProps(containerProps)),
        ...styleProp,
    }
    Layer.applyWillChange({ willChangeTransform }, style, true)

    if (__fromCodeComponentNode && !constraintsEnabled(unwrapFrameProps(containerProps))) {
        containerProps.width = "100%"
        containerProps.height = "100%"
        containerProps._constraints = { enabled: true }
    }

    const layoutId = useLayoutId(stackProps)

    const { children: _children, props } = processOverrideForwarding(containerProps, children)

    const fractionChildren = handleFraction(_children, direction)
    const gapChildren = wrapInGapElement(fractionChildren, gap, flexDirection, justifyContent)
    const content = useProvideParentSize(gapChildren, ParentSizeState.Disabled)

    return (
        <FrameWithMotion
            background={"none"} // need to set here to prevent default background from Frame
            {...props}
            layoutId={layoutId}
            data-framer-component-type={"Stack"}
            data-framer-stack-direction-reverse={isReverse}
            style={style}
        >
            {content}
        </FrameWithMotion>
    )
})

Stack.displayName = "Stack"

/**
 * Private helper function
 */
function isFractionDimension(dimension: any): dimension is string {
    return typeof dimension === "string" && dimension.endsWith("fr")
}

function fraction(dimension: string) {
    const value = parseFloat(dimension)
    return isFiniteNumber(value) ? value : 0
}

function handleFraction(children: React.ReactNode, direction: StackDirection) {
    return React.Children.map(children, child => {
        if (!isReactChild(child) || !isReactElement(child)) return
        const isVertical = direction === "vertical"
        const style: MotionStyle = {}
        let hasFraction = false
        const { style: propsStyle, size } = child.props as Partial<{
            style?: MotionStyle
            size?: number | string
        }>
        let { width, height } = child.props as Partial<{
            width?: number | string
            height?: number | string
        }>

        // convert size to width and height if they are not set already
        if (size !== undefined) {
            if (width === undefined) width = size
            if (height === undefined) height = size
        }

        let newWidth = width
        let newHeight = height
        if (isFractionDimension(width)) {
            hasFraction = true
            hasFraction = true
            if (isVertical) {
                newWidth = `${fraction(width) * 100}%`
            } else {
                newWidth = 1
                style.flexGrow = fraction(width)
                style.flexBasis = 0
            }
            style.width = newWidth
        }

        if (isFractionDimension(height)) {
            hasFraction = true
            if (isVertical) {
                newHeight = 1
                style.flexGrow = fraction(height)
                style.flexBasis = 0
            } else {
                newHeight = `${fraction(height) * 100}%`
            }
            style.height = newHeight
        }

        if (!hasFraction) return child

        const nextStyle = { ...propsStyle, ...style }
        return React.cloneElement(child, {
            width: newWidth,
            height: newHeight,
            style: nextStyle,
        } as React.BaseHTMLAttributes<HTMLDivElement>)
    })
}

function isGapEnabled(gap: number | undefined, justifyContent: React.CSSProperties["justifyContent"]): boolean {
    if (!gap) {
        return false
    }
    if (justifyContent && ["space-between", "space-around", "space-evenly", "stretch"].includes(justifyContent)) {
        return false
    }
    return true
}

function wrapInGapElement(
    children: React.ReactNode,
    gap: number | undefined,
    direction: FlexDirection,
    justifyContent: React.CSSProperties["justifyContent"]
) {
    if (!isGapEnabled(gap, justifyContent)) {
        return children
    }
    const isVertical = isVerticalDirection(direction)

    const gapStyle: React.CSSProperties = {
        display: "contents",
        ["--stack-gap-x" as any]: `${isVertical ? 0 : gap}px`,
        ["--stack-gap-y" as any]: `${isVertical ? gap : 0}px`,
    }

    return (
        <div data-framer-stack-gap={true} style={gapStyle}>
            {children}
        </div>
    )
}

function toFlexDirection(direction: StackDirection): FlexDirection {
    switch (direction) {
        case "vertical":
            return "column"
        case "horizontal":
            return "row"
        default:
            return direction
    }
}

function isVerticalDirection(direction: FlexDirection) {
    return direction === "column" || direction === "column-reverse"
}

function isReverseDirection(direction: FlexDirection) {
    switch (direction) {
        case "column-reverse":
        case "row-reverse":
            return true
        default:
            return false
    }
}

/** @internal */
export function toJustifyOrAlignment(distribution: StackDistribution | StackAlignment) {
    switch (distribution) {
        case "start":
            return "flex-start"
        case "end":
            return "flex-end"
        default:
            return distribution
    }
}

addPropertyControls(Stack, {
    direction: {
        type: ControlType.SegmentedEnum,
        options: ["horizontal", "vertical"],
        title: "Direction",
        defaultValue: "vertical",
    },
    distribution: {
        type: ControlType.Enum,
        options: ["start", "center", "end", "space-between", "space-around", "space-evenly"],
        optionTitles: ["Start", "Center", "End", "Space Between", "Space Around", "Space Evenly"],
        title: "Distribute",
        defaultValue: "space-around",
    },
    alignment: {
        type: ControlType.SegmentedEnum,
        options: ["start", "center", "end"],
        title: "Align",
        defaultValue: "center",
    },
    gap: {
        type: ControlType.Number,
        min: 0,
        title: "Gap",
        hidden: props => {
            return (
                props.distribution !== undefined &&
                ["space-between", "space-around", "space-evenly"].includes(props.distribution)
            )
        },
        defaultValue: 10,
    },
    padding: {
        type: ControlType.FusedNumber,
        toggleKey: "paddingPerSide",
        toggleTitles: ["Padding", "Padding per side"],
        valueKeys: ["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"],
        valueLabels: ["t", "r", "b", "l"],
        min: 0,
        title: "Padding",
        defaultValue: 0,
    },
})
